'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import UnstyledAccordion from '../../UnstyledAccordion';
import NavBarChild from './NavBar.Child';
import NavBarParent from './NavBar.Parent';

export type NavBarAccordionProps = {
  items?: NavMenuItem[];
};

export default function NavBarAccordion({ items }: NavBarAccordionProps) {
  return (
    <UnstyledAccordion BoxProps={{ cx: { pB: { xs: 'xs', lg: 'lg' } } }}>
      {items?.map((item, index) => {
        return (
          <UnstyledAccordion.Item
            key={item.id}
            index={index}
            style={{ border: 'none' }}
            isAccordionItem={!!item.subItems?.length}
            CaretProps={{
              openIcon: 'plus',
              closeIcon: 'minus',
              OpenIconProps: { variant: 'outlined', size: 'small' },
              CloseIconProps: { variant: 'filled', color: 'gum', size: 'small' },
            }}
            HandleProps={{ cx: { pX: { xs: 'margin', md: 'md' }, pY: '4xs' } }}
            LabelProps={{
              cx: !item.subItems?.length ? { width: 'full' } : { width: 'auto' },
              children: <NavBarParent {...{ isAccordion: !!item.subItems?.length, ...item }} />,
            }}
            BodyProps={{ cx: { pL: 'md' } }}>
            {item.subItems?.map((subItem) => {
              return <NavBarChild key={subItem.id} {...subItem} />;
            })}
          </UnstyledAccordion.Item>
        );
      })}
    </UnstyledAccordion>
  );
}
