import { fmtFullName } from '@/lib/utils/format';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardTitleProps<D extends React.ElementType = 'h2'> = TxtProps<
  D,
  {
    hover?: boolean;
  }
>;

export const CardTitle = <D extends React.ElementType>({
  children,
  hover,
  ...props
}: CardTitleProps<D>) => {
  const { firstName, lastName, titleComponent, title } = useCard();

  const fullName = fmtFullName(firstName, lastName);
  const content = fullName || title;

  return (
    <Txt
      as={titleComponent || 'h2'}
      variant="h4"
      {...mergePropsClassName(props, style.title({ hover }))}>
      {children || content}
    </Txt>
  );
};
