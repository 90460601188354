'use client';

import { SERVICE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { simplifyEntry } from '@/lib/utils/format';
import { withFragment } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, makeNonNullableArray, parseUri } from '@liquorice/utils';
import { gql } from '__generated__';
import Card from '../Card';
import Box from '../ui/Box';
import * as styles from './ArticleCard.css';
import { OverrideCardProps } from './EntryCard';

export const ARTICLE_CARD_FRAGMENT = gql(`
  fragment articleCard on article_Entry {
    id
    uri
    slug
    url
    postDate @formatDateTime(format: "j F Y")
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    serviceMultiple {
      ...serviceCard
    }
    authorMultiple {
      ...profileCard
    }
    contentTypeCategory {
      ...contentTypeCategory
    }
  }
`);

const ArticleCard = withFragment(ARTICLE_CARD_FRAGMENT, (data, props: OverrideCardProps) => {
  if (!data) return null;

  const { entryImage, entryTitle, uri, postDate, serviceMultiple } = data;
  const { heading } = props;

  const href = parseUri(uri);
  const services = excludeEmptyTypedElements(serviceMultiple);
  const categories = simplifyEntry(SERVICE_CARD_FRAGMENT, services);
  const maybeImage = makeNonNullableArray(props.image);
  const image = !!maybeImage.length ? maybeImage : entryImage;

  return (
    <Card
      disableLinkWrap
      elevation={false}
      item={{
        title: heading ?? entryTitle,
        titleComponent: 'a',
        meta: postDate,
        categories,
        image,
        href,
      }}>
      <Box as="a" href={href}>
        <Card.Image ratio="LANDSCAPE_WIDE" />
      </Box>
      <Card.Body cx={{ rowGap: 'sm', mT: 'md' }}>
        <Card.Meta variant="xs" cx={{ decoration: 'none' }} />
        <Card.Categories
          variant="outlined"
          size="tiny"
          color="gum"
          className={styles.categories}
          BoxProps={{ className: styles.categoryWrapper }}
        />
        <Card.Title href={href} variant="h6" cx={{ decoration: 'none' }} />
        <Card.Cta className={styles.cta} as="a" href={href} />
      </Card.Body>
    </Card>
  );
});

export default ArticleCard;
