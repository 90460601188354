'use client';

import { useAppContext } from '@/lib/context/hooks';
import { ColorSetKey } from '@/theme/partials/palette.css';
import { vars } from '@/theme/vars.css';
import { isTypedFragment } from '@liquorice/gql-utils';
import { Drawer } from '@mui/material';
import IconButton from '../IconButton';
import SearchBar from '../SearchBar';
import Box, { BoxProps } from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import { useNavBarActions } from './useNavBar';

type NavBarSearchProps = FlexProps<
  'div',
  {
    IconBoxProps?: BoxProps;
    timeout?: number;
  }
>;

const NavBarSearch = ({
  IconBoxProps,
  colorSet: headerColorSet,
  timeout,
  ...props
}: NavBarSearchProps) => {
  const { theme, search, setSearch, setMenu } = useNavBarActions();

  const { data } = useAppContext() ?? {};
  const isHomePage = isTypedFragment(data, 'home_Entry');
  const colorSet = isHomePage ? (theme?.colorScheme?.label as ColorSetKey) : headerColorSet;

  const toggleDrawer = (newOpen: boolean) => () => {
    setMenu(false);

    setTimeout(() => {
      setSearch(newOpen);
    }, timeout ?? 500); // 500ms delay
  };

  return (
    <>
      <Box cx={{ listStyle: 'none' }} {...IconBoxProps}>
        <IconButton icon="search" variant="outlined" size="small" onClick={toggleDrawer(true)} />
      </Box>
      <Drawer
        id="search-drawer"
        open={search}
        anchor="top"
        elevation={0}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { bgcolor: 'transparent', width: '50vw', marginLeft: 'auto' },
        }}>
        <Flex colorSet={colorSet} paper cx={{ width: 'full', pX: 'md', pY: 'xs' }} {...props}>
          <SearchBar
            SearchBarInputProps={{ style: { borderBottom: '1px solid currentColor' } }}
            SearchBarResultsProps={{
              cx: { mT: 'none' },
              disableDivider: true,
              SearchBarResultProps: {
                style: {
                  paddingTop: vars.spacing['2xs'],
                  paddingBottom: vars.spacing['2xs'],
                  borderBottom: '1px solid currentColor',
                },
              },
            }}
          />
        </Flex>
      </Drawer>
    </>
  );
};

export default NavBarSearch;
