import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardOfficeNumberProps<D extends React.ElementType = 'p'> = TxtProps<
  D,
  {
    enableLinkWrap?: boolean;
  }
>;

export const CardOfficeNumber = <D extends React.ElementType>({
  children,
  enableLinkWrap = false,
  ...props
}: CardOfficeNumberProps<D>) => {
  const { officeNumber } = useCard();
  const content = children ?? officeNumber;

  if (!content) return null;

  if (enableLinkWrap) {
    return (
      <Box>
        <Btn
          variant="text"
          color="neutral"
          as="a"
          href={'tel:' + content}
          {...mergePropsClassName(props, style.officeNumber)}>
          {content}
        </Btn>
      </Box>
    );
  }

  return (
    <Txt as="span" color="black" {...mergePropsClassName(props, style.officeNumber)}>
      {content}
    </Txt>
  );
};
