'use client';

import { parseUri } from '@liquorice/utils';
import { ProfileCardFragment } from '__generated__/graphql';
import Box from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import { KeyContactType } from './KeyContacts';

type KeyContactsCtaProps = BtnProps<
  'a',
  {
    indexUri?: string | null;
    items?: ProfileCardFragment[];
    id?: string | null;
    type?: KeyContactType;
  }
>;

export const KeyContactsCta = ({
  children,
  indexUri,
  type,
  id,
  items,
  ...props
}: KeyContactsCtaProps) => {
  let uri: string | null = null;

  if (type === 'sector' || type === 'expertise') {
    uri = `${indexUri}?${type}=${id}`;
  } else if (type === 'article' || type === 'insight') {
    uri = `${indexUri}?id=${items?.map((item) => `${item.id}`).join(',')}`;
  }

  return (
    uri && (
      <Box>
        <Btn
          variant="nav"
          underline="none"
          size="small"
          endIcon="arrowRight"
          href={parseUri(uri)}
          {...props}>
          {children}
        </Btn>
      </Box>
    )
  );
};

export default KeyContactsCta;
