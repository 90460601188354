import { useCallback } from 'react';
import { DEFAULT_PER_PAGE, useEntryIndex } from './useEntryIndex';

export const useEntryIndexPagination = () => {
  const {
    page = 1,
    setPage,
    perPage = DEFAULT_PER_PAGE,
    total: totalResults = 0,
  } = useEntryIndex((s) => ({
    page: s.page,
    setPage: s.setPage,
    perPage: s.perPage,
    total: s.totalItems,
  }));

  const totalItems = totalResults ? Math.ceil(totalResults / perPage) : 1;
  const nextPage = useCallback(
    () => setPage(Math.min(page + 1, totalItems)),
    [page, setPage, totalItems]
  );
  const prevPage = useCallback(() => setPage(Math.max(page - 1, 1)), [page, setPage]);
  const goToPage = useCallback(
    (page: number) => setPage(Math.max(Math.min(page, totalItems), 1)),
    [setPage, totalItems]
  );

  return {
    page,
    totalResults,
    totalItems,
    nextPage,
    prevPage,
    goToPage,
  };
};
