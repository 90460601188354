import { CollectionId, ElementTreeItem, ListElement } from './parseElementList';

/**
 * Create a tree of elements from a flat list of elements
 */

export const makeElementTree = (
  list: ListElement[],
  collectionId: CollectionId = 'root',
  branchId: string | null = null
): ElementTreeItem[] => {
  /** Get all children of the current branch */
  const children = list.filter((item) => {
    const parentId = item.parentId;
    return parentId === branchId;
  });

  /** Recursively create a tree of elements */
  const tree = children.map((item) => {
    return {
      collectionId,
      id: item.id!,
      title: item.title!,
      children: makeElementTree(list, collectionId, item.id),
      order: item.order,
    };
  });

  return tree;
  // return tree.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
};
