'use client';

import { vars } from '@/theme/vars.css';
import { Menu, MenuItem } from '@mui/material';
import { EntryBaseFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import CtaButton from '../CtaButton';
import Btn from '../ui/Btn';

export default function PageShare({ url, title }: EntryBaseFragment) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const t = useTranslations();
  const open = Boolean(anchorEl);

  if (!url) return null;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Linkedin
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

  // Twitter
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;

  // Copy to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    // alert('Link copied to clipboard');
    setAnchorEl(null);
  };

  return (
    <>
      <CtaButton
        style={{ fontSize: vars.typography.size.xs }}
        endIcon={undefined}
        underline="none"
        startIcon="share"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {t('actions.share')}
      </CtaButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{ sx: { p: 0 } }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={handleClose}>
          <Btn
            variant="text"
            size="tiny"
            underline="none"
            target="_blank"
            onClick={handleClose}
            href={linkedinUrl}>
            {t('social.linkedin')}
          </Btn>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Btn
            variant="text"
            size="tiny"
            underline="none"
            target="_blank"
            onClick={handleClose}
            href={twitterUrl}>
            {t('social.twitter')}
          </Btn>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Btn variant="text" size="tiny" underline="none" onClick={handleCopyLink}>
            {t('social.copy')}
          </Btn>
        </MenuItem>
      </Menu>
    </>
  );
}
