import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import { useTranslations } from 'use-intl';
import Btn, { BtnComponent, BtnProps } from '../ui/Btn';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardCtaProps<D extends React.ElementType = 'button'> = BtnProps<D>;

export const CardCta: BtnComponent = <D extends React.ElementType>({
  children,
  ...props
}: CardCtaProps<D>) => {
  const t = useTranslations();
  const { customText, title } = useCard();

  return (
    <Btn
      variant="nav"
      underline="always"
      aria-label={`${t('actions.readMore')} ${t('common.about')} ${title}`}
      {...mergePropsClassName(props, style.cta)}>
      {children || customText || t('actions.readMore')}
    </Btn>
  );
};
