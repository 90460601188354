import { IconName } from '../Icon';
import IconButton, { IconButtonProps } from '../IconButton';
import Box, { BoxProps } from '../ui/Box';

export type UnstyledAccordionItemCaretProps = {
  isActive?: boolean;
  isAccordionItem?: boolean;
  openIcon?: IconName;
  OpenIconProps?: Omit<IconButtonProps, 'icon'>;
  closeIcon?: IconName;
  CloseIconProps?: Omit<IconButtonProps, 'icon'>;
  IconWrapper?: BoxProps;
};

const UnstyledAccordionItemCaret = ({
  openIcon = 'chevronUp',
  OpenIconProps,
  closeIcon = 'chevronDown',
  CloseIconProps,
  isAccordionItem = true,
  isActive,
  IconWrapper,
}: UnstyledAccordionItemCaretProps) => {
  return (
    isAccordionItem && (
      <Box {...IconWrapper}>
        <IconButton
          icon={isActive ? closeIcon : openIcon}
          {...(isActive ? CloseIconProps : OpenIconProps)}
        />
      </Box>
    )
  );
};

export default UnstyledAccordionItemCaret;
