'use client';

import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import IconButton, { IconButtonProps } from '@/components/IconButton';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';
import * as styles from './EntryIndex.css';
import useEntryIndexSubmitButton from './useEntryIndexSubmitButton';

export const useEntryIndexSearchField = (): React.ComponentPropsWithRef<'input'> => {
  const ref = React.useRef<HTMLInputElement>(null);
  const searchValue = useEntryIndex((s) => s.search);
  const [value, setValue] = React.useState<string | null>(null);

  const t = useTranslations('entryIndex');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  return {
    ref,
    type: 'text',
    placeholder: t('search'),
    onChange: handleChange,
    value: value ?? '',
  };
};

export const useEntryIndexSearchForm = (): {
  formProps: React.ComponentPropsWithRef<'form'>;
  inputProps: React.ComponentPropsWithRef<'input'>;
  searchSummary: string | null;
} => {
  const ref = React.useRef<HTMLFormElement>(null);
  const inputProps = useEntryIndexSearchField();
  const searchSummary = useEntryIndexSearchSummary();
  const setSearchValue = useEntryIndex((s) => s.setSearch);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit');
    e.preventDefault();
    setSearchValue(inputProps.value as string);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchValue('');
  };

  return {
    formProps: {
      ref,
      onSubmit: handleSubmit,
      onReset: handleReset,
    },
    inputProps,
    searchSummary,
  };
};

const useEntryIndexSearchSummary = () => {
  const loading = useEntryIndex((s) => s.loading);
  const query = useEntryIndex((s) => s.search);
  const count = useEntryIndex((s) => s.totalItems ?? 0);
  const perPage = useEntryIndex((s) => s.perPage ?? 0);
  const countVisible = Math.min(count, perPage);

  const t = useTranslations('search');

  if (!query) return null;

  if (loading) return t('loading', { query });

  return t('summary', { query, count, countVisible });
};

export const EntryIndexSearchSummary = (props: TxtProps<'div'>) => {
  const text = useEntryIndexSearchSummary();

  return <Txt {...props}>{text}</Txt>;
};

type EntryIndexSearchFormProps = BoxProps<
  'form',
  {
    InputProps?: BoxProps<'input'>;
    IconButtonProps?: Omit<IconButtonProps, 'icon'>;
  }
>;

const EntryIndexSearchForm = ({
  InputProps,
  IconButtonProps,
  ...props
}: EntryIndexSearchFormProps) => {
  const { inputProps, /* searchSummary, */ formProps } = useEntryIndexSearchForm();
  const submitButtonProps = useEntryIndexSubmitButton();

  return (
    <Flex
      as="form"
      direction="row"
      columnGap="xs"
      {...mergePropsClassName(props, styles.searchForm)}
      {...formProps}>
      <Box
        as="input"
        cx={{ fontSize: 'md' }}
        {...inputProps}
        {...mergePropsClassName(InputProps, styles.searchInput)}
      />
      <Flex justifyContent="center">
        <IconButton
          icon="search"
          color="gum"
          variant="outlined"
          {...(submitButtonProps as BtnProps)}
          {...mergePropsClassName(IconButtonProps, styles.searchButton)}
        />
      </Flex>
    </Flex>
  );
};

export default EntryIndexSearchForm;
