'use client';

import { useNextHydrated } from '@/lib/utils/createToggleStore';
import { forwardRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

export type VideoPlayerProps = ReactPlayerProps;

export const VideoPlayer = forwardRef<ReactPlayer, ReactPlayerProps>(function VideoPlayer(
  { config, ...props },
  ref
) {
  const nextHydrated = useNextHydrated();
  if (!nextHydrated) return null;

  return (
    <ReactPlayer
      ref={ref}
      {...{
        height: '100%',
        width: '100%',
        playsinline: true,

        controls: true,
        playing: false,
        loop: false,
        muted: false,
        ...props,
        config: {
          youtube: {
            playerVars: {
              showinfo: 0,
              color: 'white',
              modestbranding: 1,
            },
          },
          vimeo: {
            playerOptions: {},
          },
          ...config,
        },
      }}
    />
  );
});
