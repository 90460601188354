import { ThemeEntryFragment } from '__generated__/graphql';
import { create } from 'zustand';

export type NavBarResponsiveVariant = 'mobile' | 'desktop';

export type NavBar = {
  responsiveVariant: NavBarResponsiveVariant;
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => void;

  fallbackHeight: number;
  height?: number | undefined;
  setHeight: (height: number | undefined) => void;

  search: boolean;
  setSearch: (search: boolean) => void;

  menu: boolean;
  setMenu: (menu: boolean) => void;

  theme: ThemeEntryFragment | null;
  setTheme: (theme: ThemeEntryFragment) => void;
};

export const useNavBarActions = create<NavBar>()((set) => ({
  responsiveVariant: 'desktop',
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => set({ responsiveVariant }),

  fallbackHeight: 73,
  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),

  search: false,
  setSearch: (search?: boolean) => set(() => ({ search })),

  menu: false,
  setMenu: (menu?: boolean) => set(() => ({ menu })),

  theme: null,
  setTheme: (theme: ThemeEntryFragment) => set(() => ({ theme })),
}));
