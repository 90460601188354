'use client';

import { parseLinkField } from '@/lib/parsers/linkField';
import { GlobalSubscribeFormFragment } from '__generated__/graphql';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';

const FooterSubscribe = ({ data }: { data?: GlobalSubscribeFormFragment }) => {
  const linkField = parseLinkField(data?.linkField);

  return (
    <>
      {data?.label && (
        <Txt as="p" variant="sm">
          {data?.label}
        </Txt>
      )}
      <Flex direction="row" spacing="xs" wrap>
        {linkField?.map((link, i) => {
          return <Btn key={i} variant="outlined" size="small" {...link} />;
        })}
      </Flex>
    </>
  );
};

export default FooterSubscribe;
