'use client';

import BlockHeader from '@/components/BlockHeader';
import TwoColumnContent from '@/components/TwoColumnContent';
import { createBlock } from '@/lib/parsers/blocks/createBlock';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { useTranslations } from 'next-intl';
import { BlockContainer } from '../../BlockContainer';
import GenericContentBlock from '../GenericContentBlock';

const TWO_COLUMN_CONTENT_BLOCK = gql(`
  fragment twoColumnContentBlock on blocks_twoColumnContent_BlockType {
    heading
    children {
      ...genericContentBlock
    }
  }   
`);

const RecentMattersBlock = createBlock(TWO_COLUMN_CONTENT_BLOCK, ({ data, meta }) => {
  const t = useTranslations('common');
  const { children, heading } = data;

  const items = excludeEmptyTypedElements(children);

  return (
    <BlockContainer {...{ meta, marginY: true }}>
      <BlockHeader
        heading={heading ?? t('recentMatters')}
        HeadingProps={{ cx: { mB: 'gutter' } }}
      />
      <TwoColumnContent
        items={items?.map((child, i) => {
          return <GenericContentBlock key={i} data={child} />;
        })}
      />
    </BlockContainer>
  );
});

export default RecentMattersBlock;
