'use client';

import { useAppContext } from '@/lib/context/hooks';
import { parseColorScheme } from '@/lib/parsers/common/parseColorScheme';
import { NavMenu } from '@/lib/parsers/navigation';
import { vars } from '@/theme/vars.css';
import { isTypedFragment } from '@liquorice/gql-utils';
import { Drawer } from '@mui/material';
import { useState } from 'react';
import IconButton from '../IconButton';
import Logo from '../Logo';
import SearchBar from '../SearchBar';
import Box, { BoxProps } from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import NavBarAccordion from './Accordion/NavBar.Accordion';
import * as styles from './NavBar.Drawer.css';
import NavBarDrawerSearchButton from './NavBar.Drawer.Search';
import NavBarSearch from './NavBar.Search';
import { useNavBarActions } from './useNavBar';

type NavBarDrawerProps = FlexProps<
  'div',
  {
    NavMenu: NavMenu;
    IconBoxProps?: BoxProps;
  }
>;

export default function NavBarDrawer({
  NavMenu,
  IconBoxProps,
  colorSet: headerColorSet,
  ...props
}: NavBarDrawerProps) {
  const { items, name } = NavMenu;
  const { theme, menu, setMenu } = useNavBarActions();
  const [search, setSearch] = useState<boolean>(false);

  const { data } = useAppContext() ?? {};
  const isHomePage = isTypedFragment(data, 'home_Entry');
  const colorSet = isHomePage ? parseColorScheme(theme?.colorScheme) : headerColorSet;

  const toggleDrawer = (newOpen: boolean) => () => {
    setMenu(newOpen);
    setSearch(false);
  };

  return (
    <>
      <Box cx={{ listStyle: 'none' }} {...IconBoxProps}>
        <IconButton icon="menu" variant="outlined" size="small" onClick={toggleDrawer(true)} />
      </Box>
      <Drawer
        id={name}
        open={menu}
        anchor="top"
        elevation={0}
        onClose={toggleDrawer(false)}
        PaperProps={{
          className: styles.root,
        }}>
        <Flex colorSet={colorSet} paper cx={{ width: 'full', height: 'full' }} {...props}>
          <Flex
            as="ul"
            direction="row"
            justifyContent="end"
            cx={{
              pX: { xs: 'margin', md: 'gutter' },
              pY: 'lg',
              columnGap: { xs: 'lg', lg: '2xs' },
              justifyContent: { xs: 'between', lg: 'end' },
              listStyle: 'none',
            }}>
            <Box as="li" cx={{ display: { xs: 'block', lg: 'none' } }}>
              <Logo />
            </Box>
            <NavBarSearch IconBoxProps={{ cx: { display: { xs: 'none', lg: 'block' } } }} />
            <Box as="li">
              <IconButton
                icon="close"
                onClick={toggleDrawer(false)}
                variant="outlined"
                size="small"
              />
            </Box>
          </Flex>
          {!search ? (
            <>
              <NavBarAccordion {...{ items }} />
              <NavBarDrawerSearchButton onClick={() => setSearch(!search)} />
            </>
          ) : (
            <Box cx={{ mX: 'margin' }}>
              <SearchBar
                SearchBarInputProps={{
                  style: { borderBottom: '1px solid currentColor' },
                  CloseIconProps: { style: { display: 'none' } },
                }}
                SearchBarResultsProps={{
                  cx: { mT: 'none' },
                  disableDivider: true,
                  SearchBarResultProps: {
                    style: {
                      paddingTop: vars.spacing['2xs'],
                      paddingBottom: vars.spacing['2xs'],
                      borderBottom: '1px solid currentColor',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Flex>
      </Drawer>
    </>
  );
}
