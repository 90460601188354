import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import NextAnchor from '../ui/NextAnchor';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardEmailProps<D extends React.ElementType = 'p'> = TxtProps<
  D,
  {
    enableLinkWrap?: boolean;
  }
>;

export const CardEmail = <D extends React.ElementType>({
  children,
  enableLinkWrap = false,
  ...props
}: CardEmailProps<D>) => {
  const { email } = useCard();
  const content = children ?? email;

  if (!content) return null;

  const inner = (
    <Txt as="span" color="black" {...mergePropsClassName(props, style.email)}>
      {content}
    </Txt>
  );

  if (enableLinkWrap) {
    return (
      <NextAnchor
        style={{ textDecoration: 'none', overflowWrap: 'anywhere', whiteSpace: 'normal' }}
        href={'mailto:' + email}>
        {inner}
      </NextAnchor>
    );
  }

  return inner;
};
