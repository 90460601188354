import { appContext, AppContext } from '@/components/Entry/EntryProvider';
import React from 'react';

export const useAppContext = (): AppContext | null => {
  const store = React.useContext(appContext);

  if (!store) return null;

  return store;
};
