import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardDescriptionProps<D extends React.ElementType = 'span'> = TxtProps<D>;

export const CardDescription = <D extends React.ElementType>({
  children,
  ...props
}: CardDescriptionProps<D>) => {
  const { description } = useCard();
  const content = children || description;

  return (
    content && (
      <Txt as="span" variant="body" html {...mergePropsClassName(props, style.description)}>
        {content}
      </Txt>
    )
  );
};
