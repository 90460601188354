'use client';

import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import Shim, { ShimProps } from '../Shim';
import * as styles from './NavBar.css';
import { useNavBarActions } from './useNavBar';

type NavBarShimProps = { typeHandle?: string | null } & ShimProps;

export default function NavBarShim({ typeHandle, ...props }: NavBarShimProps) {
  const { height, fallbackHeight } = useNavBarActions();

  if (typeHandle === 'home') return null;

  return (
    <Shim
      relative={false}
      {...mergePropsClassName(props, styles.shim)}
      style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}
    />
  );
}
