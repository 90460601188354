import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 16C6 21.5208 10.4792 26 16 26C21.5208 26 26 21.5208 26 16C26 10.4792 21.5208 6 16 6C10.4792 6 6 10.4792 6 16Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComponent;
