import { Color } from '@/theme/partials/palette.css';
import { PAGE_HEADER_COLORS, PageHeaderColor, PageHeaderKey } from '../constants';

export const getHeaderColor = (
  headerColor: string | undefined | null,
  override?: Color
): Color | undefined => {
  if (!headerColor) return undefined;

  if (override) {
    return override as PageHeaderColor;
  }

  if (headerColor in PAGE_HEADER_COLORS) {
    return PAGE_HEADER_COLORS[headerColor as PageHeaderKey];
  }
  return undefined; // Return undefined if the key is not found
};
