import { gql } from '__generated__';

export const BLOCKS_FRAGMENT = gql(`
    fragment blocks on blocks_NeoField {
        ...richTextBlock
        ...accordionBlock
        # ...accordionItemBlock
        ...quoteMultipleBlock
        # ...quoteSingleBlock
        ...twoColumnContentBlock

        # Cards
        # ...insightCardBlock
        # ...articleCardBlock
        # ...pageCardBlock
        # ...profileCardBlock
        ...entryCardsBlock
        ...guideCardsBlock
        ...highlightCardsBlock
        ...profileCardsBlock
        ...latestArticlesBlock
        ...latestInsightsBlock
        
        # Media
        ...imageBlock
        ...imageCarouselBlock
        ...imageAndTextBlock
        ...videoBlock
        ...pdfDownloadBlock
        ...formBlock
    }    
`);
