'use client';

import { useBreakpointMax, useBreakpointMin } from '@/theme';
import { useEffect, useState } from 'react';

type ResponseBreakpointProps = React.PropsWithChildren<{
  isDesktop?: boolean;
  isMobile?: boolean;
}>;

export default function ResponsiveBreakpoint({
  children,
  isDesktop,
  isMobile,
}: ResponseBreakpointProps) {
  const maxLg = useBreakpointMax('lg');
  const minLg = useBreakpointMin('lg');

  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    if (isDesktop) {
      setValue(minLg);
    } else if (isMobile) {
      setValue(maxLg);
    }
  }, [maxLg, minLg, isMobile, isDesktop]);

  if (!value) return null;

  return children;
}
