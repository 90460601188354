import Box, { BoxProps } from '../ui/Box';
import Divider from '../ui/Divider';
import Txt from '../ui/Txt';

export type BasicContent = BoxProps<
  'div',
  {
    heading?: string | null;
    content?: string | null;
  }
>;

export default function BasicContent({ heading, content, ...props }: BasicContent) {
  if (!heading || !content) return null;

  return (
    <Box {...props}>
      <Divider />
      <Txt as="p" variant="md" heading cx={{ mY: '2xs' }}>
        {heading}
      </Txt>
      <Txt variant="sm" html>
        {content}
      </Txt>
    </Box>
  );
}
