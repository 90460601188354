'use client';

import {
  useDepreciatedEntryIndexFilter,
  useEntryIndexFilterItem,
} from '@/components/EntryIndex/useEntryIndexFilter_DEP';
import Box from '@/components/ui/Box';
import Flex, { FlexProps } from '@/components/ui/Flex';
import { ElementTree, ElementTreeItem } from '@/lib/parsers/common/parseElementList';
import { Collapse } from '@mui/material';
import React from 'react';
import IconButton from '../IconButton';
import Btn from '../ui/Btn';
import * as styles from './EntryIndex.css';
import { FilterSliceState } from './entryIndexTypes';

export type FilterItemProps = {
  item: ElementTreeItem;
};

const FilterItem = ({ item }: FilterItemProps) => {
  const { active, toggle } = useEntryIndexFilterItem(item);

  const idStr = `filter-input-${item.id}`;

  return (
    <Box as="li">
      <Btn
        as="label"
        size="small"
        color="gum"
        variant={active ? 'filled' : 'outlined'}
        id={`${idStr}-label`}
        onChange={() => toggle()}
        className={styles.inputText}>
        <input
          className={styles.inputCheckbox}
          id={item.id}
          type="checkbox"
          checked={active}
          onChange={() => toggle()}
        />{' '}
        {item.title}
      </Btn>
    </Box>
  );
};

type FilterListProps = FlexProps<
  'ul',
  {
    items: ElementTree;
  }
>;

/**
 * Recursively display list of filters
 */
const FilterList = ({ items, ...props }: FilterListProps) => {
  return (
    <Flex
      as="ul"
      direction="row"
      wrap
      cx={{ rowGap: 'xs', columnGap: 'xs', listStyle: 'none' }}
      {...props}>
      {items.map((item) => (
        <FilterItem key={item.id} item={item} />
      ))}
    </Flex>
  );
};

export type EntryIndexFilterProps = {
  filter: FilterSliceState;
};

const EntryIndexFilter = ({ filter }: EntryIndexFilterProps) => {
  const { itemTree } = useDepreciatedEntryIndexFilter(filter.id);

  return itemTree.length ? (
    <Box className={styles.indexFilter} as="fieldset" id={filter.id}>
      <FilterList items={itemTree} />
    </Box>
  ) : null;
};

export type EntryIndexFilterButtonsProps = {
  filters: FilterSliceState[];
};

const EntryIndexFilterButtons = ({ filters }: EntryIndexFilterButtonsProps) => {
  const [open, setOpen] = React.useState<string | null>(null);

  const handleClick = (id: string) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  return (
    <Box>
      <Flex
        cx={{
          direction: { md: 'row' },
          alignItems: 'start',
          rowGap: { xs: 'xl', md: 'sm' },
          columnGap: 'sm',
        }}>
        {filters.map((filter) => (
          <Btn
            key={`collapse-button-${filter.id}`}
            size="large"
            variant="nav"
            underline="none"
            onClick={() => handleClick(filter.id)}>
            {filter.label}
            <IconButton
              as="span"
              color={filter.id === open ? 'gum' : 'black'}
              variant={filter.id === open ? 'filled' : 'outlined'}
              icon={filter.id === open ? 'minus' : 'plus'}
            />
          </Btn>
        ))}
      </Flex>
      <Box cx={{ mT: 'md' }}>
        {filters.map((filter, index) => {
          return (
            <Collapse key={`collapse-filter-${filter.id}`} in={filter.id === open}>
              <EntryIndexFilter key={index} filter={filter} />
            </Collapse>
          );
        })}
      </Box>
    </Box>
  );
};

export default EntryIndexFilterButtons;
