'use client';

import { useAppContext } from '@/lib/context/hooks';
import { NavMenuItem } from '@/lib/parsers/navigation';
import { ColorSetKey } from '@/theme/partials/palette.css';
import { isTypedFragment } from '@liquorice/gql-utils';
import { Menu } from '@mui/material';
import { useRef, useState } from 'react';
import IconButton from '../IconButton';
import Shim from '../Shim';
import Box, { BoxProps } from '../ui/Box';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import * as styles from './NavBar.css';
import { useNavBarActions } from './useNavBar';

type NavBarItemProps = BoxProps<
  'li',
  {
    NavMenuItem: NavMenuItem;
    bgcolor?: ColorSetKey;
  }
>;

export default function NavBarItem({ NavMenuItem, bgcolor, ...props }: NavBarItemProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navItemRef = useRef<null | HTMLLIElement>(null);
  const navTitleRef = useRef<null | HTMLButtonElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, subItems, newWindow, external, parentId, title, ...rest } = NavMenuItem;
  const hasSubItems = !!subItems?.length;

  const { theme } = useNavBarActions();
  const { data } = useAppContext() ?? {};
  const isHomePage = isTypedFragment(data, 'home_Entry');

  const colorSet = isHomePage ? (theme?.colorScheme?.label as ColorSetKey) : bgcolor;

  const width = navItemRef.current?.offsetWidth;

  const handleClick = () => {
    setAnchorEl(navTitleRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box as="li" ref={navItemRef} {...props}>
      <Flex direction="row">
        <Btn ref={navTitleRef} variant="nav" size="small" {...rest}>
          {title}
        </Btn>
        {hasSubItems && (
          <IconButton
            cx={{ fontSize: '2xs' }}
            icon={open ? 'chevronUp' : 'chevronDown'}
            onClick={handleClick}
          />
        )}
      </Flex>
      {hasSubItems && (
        <Menu
          elevation={0}
          PaperProps={{ sx: { borderRadius: 0, overflow: 'visible' } }}
          MenuListProps={{ sx: { pt: 0, pb: 0 } }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <Shim
            className={styles.menuShim}
            style={{ width: width ? `${width + 18}px` : undefined }}
            cx={{ bgcolor: colorSet }}
          />
          <Flex as="ul" cx={{ listStyle: 'none', pT: isHomePage ? '2xs' : 'none' }}>
            {subItems.map((subItem) => (
              <NavBarItem
                key={subItem.id}
                cx={{ zIndex: 'navbar' }}
                {...{ NavMenuItem: subItem }}
              />
            ))}
          </Flex>
        </Menu>
      )}
    </Box>
  );
}
