'use client';

import { useTranslations } from 'next-intl';
import Txt, { TxtProps } from '../ui/Txt';

type FooterCopyrightProps = TxtProps<
  'p',
  {
    label?: string | null;
  }
>;

const FooterCopyright = ({ label, ...props }: FooterCopyrightProps) => {
  const t = useTranslations('common');
  if (!label) return null;

  return (
    <Txt variant="2xs" {...props}>{`${t(
      'copyrightSymbol'
    )} ${new Date().getFullYear()} ${label}. ${t('rights')}.`}</Txt>
  );
};

export default FooterCopyright;
