'use client';

import EntryIndexFilterButtons from '@/components/EntryIndex/EntryIndex.Filter';
import EntryIndexSummary from '@/components/EntryIndex/EntryIndex.Summary';
import EntryIndexTitle from '@/components/EntryIndex/EntryIndex.Title';
import { FILTERS_BREAKPOINT } from '@/components/EntryIndex/entryIndexConstants';
import { FilterSliceState } from '@/components/EntryIndex/entryIndexTypes';
import IconButton from '@/components/IconButton';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import { useBreakpointMax } from '@/theme';
import { vars } from '@/theme/vars.css';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslations } from 'next-intl';
import React from 'react';
import SearchBar, { SearchBarProps } from '../SearchBar';
import Box from '../ui/Box';
import Grid from '../ui/Grid';

export type EntryIndexFiltersProps = {
  filtersConfig?: FilterSliceState[];
  SearchBarProps?: SearchBarProps;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EntryIndexFilters = ({ filtersConfig = [], SearchBarProps }: EntryIndexFiltersProps) => {
  const showDialog = useBreakpointMax(FILTERS_BREAKPOINT);
  const t = useTranslations('entryIndex');
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open);

  const inner = (
    <Grid cx={{ alignItems: 'start' }}>
      <Grid.Col md={9}>
        <EntryIndexFilterButtons filters={filtersConfig} />
      </Grid.Col>
      <Grid.Col md={3} cx={{ pT: { xs: '2xs', md: 'none' } }}>
        <SearchBar
          SearchBarInputProps={{
            cx: { pT: 'none' },
            CloseIconProps: { style: { display: 'none' } },
          }}
          SearchBarResultsProps={{
            // disableLoadStates: true,
            disableOutboundLink: true,
            style: {
              position: 'absolute',
              width: '100%',
              backgroundColor: vars.palette.colorSets.white.main,
            },
            SearchBarResultProps: {
              LabelProps: { style: { display: 'none' } },
            },
          }}
          {...SearchBarProps}
        />
      </Grid.Col>
    </Grid>
  );

  return !showDialog ? (
    inner
  ) : (
    <Flex direction="column" cx={{ rowGap: 'sm', pB: 'xl' }}>
      <Box>
        <Btn
          size="small"
          onClick={toggleOpen}
          variant="outlined"
          style={{
            fontSize: vars.typography.size.lg,
            padding: `${16 / 16}em`,
          }}
          cx={{
            display: { xs: 'block', sm: 'none' },
          }}>
          {t('filterAndSearch')}
        </Btn>
      </Box>
      <Dialog open={open} fullScreen TransitionComponent={Transition}>
        <Flex direction="column" cx={{ pX: 'margin', pY: 'lg', rowGap: 'lg' }}>
          <Flex direction="row" justifyContent="between">
            <EntryIndexTitle variant="h4" message={t('filterResults')} />
            <Box>
              <IconButton variant="outlined" icon="close" onClick={toggleOpen} />
            </Box>
          </Flex>
          {inner}
        </Flex>
      </Dialog>
      <EntryIndexSummary />
    </Flex>
  );
};

export default EntryIndexFilters;
