import { gql } from '__generated__';

export const HOME_CARD_FRAGMENT = gql(`
    fragment homeCard on home_Entry {
      id
      uri
      slug
      url
      entryTitle
      entrySummary
      entryImage {
        ...ImageAsset
      }
    }
`);

export const SERVICE_CARD_FRAGMENT = gql(`
  fragment serviceCard on service_Entry {
    id
    uri
    slug
    url
    sectionHandle
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
  }
`);

export const ENTRY_CARD_FRAGMENTS = gql(`
    fragment entryCards on EntryInterface {
        ...articleCard
        ...articleIndexCard
        ...homeCard
        ...insightCard
        ...overviewCard
        ...standardCard
        ...profileCard
        ...profileIndexCard
        ...serviceCard
    }    
`);
