import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, isTypedElement, MaybeArrayOf } from '@liquorice/utils';
import { FragmentType, gql } from '__generated__';
import { useTranslations } from 'next-intl';
import Btn, { BtnProps } from '../ui/Btn';

const VCARD_ASSET_FRAGMENT = gql(`
  fragment vCardAsset on AssetInterface {
    ...on vCardsVolume_Asset {
      __typename
      uid
      url
      height
      width
      alt
    }
  }
`);

export type VcardAssetOwnProps = BtnProps<'a'>;

export type VcardAssetProps = VcardAssetOwnProps & {
  data?: MaybeVCard;
};

export type MaybeVCard = MaybeArrayOf<FragmentType<typeof VCARD_ASSET_FRAGMENT>>;

const VcardAsset = withFragmentArray(
  VCARD_ASSET_FRAGMENT,
  (vCards, { ...props }: VcardAssetOwnProps) => {
    const vCard = firstNonNullable(vCards);
    const t = useTranslations('common');
    if (!isTypedElement(vCard, 'vCardsVolume_Asset')) return null;

    if (!vCard.url) return null;

    return (
      <Btn
        as="a"
        variant="text"
        startIcon="openOutline"
        target="_blank"
        underline="none"
        href={vCard.url}
        {...props}>
        {t('vCard')}
      </Btn>
    );
  }
);

export default VcardAsset;
