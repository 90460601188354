import EntryIndexProvider from '@/components/EntryIndex/EntryIndex.Provider';
import SearchBarInput, { SearchBarInputProps } from '@/components/SearchBar/SearchBar.Input';
import SearchBarResults, { SearchBarResultsProps } from '@/components/SearchBar/SearchBar.Results';
import { SEARCHABLE_ENTRY_TYPES } from '@/lib/constants';
import { mergePropsClassName } from '@liquorice/utils';
import { Box } from '@mui/material';
import { Suspense } from 'react';
import { EntryIndexVariables } from '../EntryIndex/entryIndexTypes';
import { BoxProps } from '../ui/Box';
import * as styles from './SearchBar.css';

export type SearchBarProps = {
  customVariables?: EntryIndexVariables;
  SearchBarInputProps?: SearchBarInputProps;
  SearchBarResultsProps?: SearchBarResultsProps;
  InnerProps?: BoxProps;
};

const SearchBar = ({
  customVariables,
  SearchBarInputProps,
  SearchBarResultsProps,
  InnerProps,
}: SearchBarProps) => {
  const variables: EntryIndexVariables = customVariables || { section: SEARCHABLE_ENTRY_TYPES };

  return (
    <Suspense>
      <EntryIndexProvider
        perPage={5}
        baseEntryQuery={variables}
        filtersConfig={[]}
        autoSubmit={false}
        skipInitialQuery>
        <Box className={styles.root}>
          <Box {...mergePropsClassName(InnerProps, styles.inner)}>
            <SearchBarInput {...SearchBarInputProps} />
            <SearchBarResults {...SearchBarResultsProps} />
          </Box>
        </Box>
      </EntryIndexProvider>
    </Suspense>
  );
};

export default SearchBar;
