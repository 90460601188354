'use client';

import { useTranslations } from 'next-intl';
import Txt from '../ui/Txt';

type EntryIndexNoResultsProps = {
  message?: string;
};

const EntryIndexNoResults = ({ message: customMessage }: EntryIndexNoResultsProps) => {
  const t = useTranslations('entryIndex');

  const message = customMessage || t('noResults');

  return <Txt>{message}</Txt>;
};

export default EntryIndexNoResults;
