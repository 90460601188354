import Flex, { FlexProps } from '@/components/ui/Flex';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import * as style from './BlockHeader.css';

export type BlockHeaderProps = FlexProps<
  'div',
  {
    children?: React.ReactNode;
    className?: string;
    heading?: React.ReactNode;
    HeadingProps?: TxtProps;
    HeadingContainerProps?: FlexProps;
    content?: React.ReactNode;
    ContentProps?: TxtProps;
  } & style.BlockHeaderVariants
>;

const BlockHeader = ({
  children,
  heading,
  HeadingProps,
  HeadingContainerProps,
  content,
  ContentProps,
  maxWidth,
  ...props
}: BlockHeaderProps) => {
  return (
    (heading || content) && (
      <Flex alignItems="start" {...mergePropsClassName(props, style.root({ maxWidth }))}>
        {heading && (
          <Flex
            direction="row"
            alignItems="start"
            columnGap="xs"
            rowGap="xs"
            {...HeadingContainerProps}>
            <Txt variant="h5" as="h5" autoMargin {...HeadingProps}>
              {heading}
            </Txt>
          </Flex>
        )}
        {content && (
          <Txt as="div" variant="body" {...ContentProps} html>
            {content}
          </Txt>
        )}
        {children}
      </Flex>
    )
  );
};

export default BlockHeader;
