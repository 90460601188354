'use client';

import { selectIsFiltered } from '@/components/EntryIndex/entryIndexSelectors';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useTranslations } from 'next-intl';
import Txt, { TxtProps } from '../ui/Txt';

type EntryIndexSummary = TxtProps<
  'p',
  {
    query?: string;
  }
>;

const EntryIndexSummary = ({ query, ...props }: EntryIndexSummary) => {
  const total = useEntryIndex((s) => s.totalItems);
  const t = useTranslations('entryIndex');
  const isFiltered = useEntryIndex(selectIsFiltered);
  const isLoading = useEntryIndex((s) => s.loading);
  const initialLoaded = useEntryIndex((s) => s.initialLoaded);
  const showLoading = isLoading || !initialLoaded;
  const message = showLoading ? t('itemsLoading') : t('allResults', { count: total, query });

  if (isFiltered) return null;

  return (
    <Txt color="black" as="p" variant="md" {...props}>
      {message}
    </Txt>
  );
};

export default EntryIndexSummary;
