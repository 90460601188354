import { LogoSvg_Mono } from '@/components/Logo/Logo.Mono';
import Box, { BoxProps } from '@/components/ui/Box';
import NextAnchor from '@/components/ui/NextAnchor';
import { mergeProps, mergePropsClassName } from '@liquorice/utils';
import * as style from './Logo.css';
import { LogoSvg_Primary } from './Logo.Primary';

export type LogoProps = BoxProps<
  'span',
  {
    homeLink?: boolean;
    variant?: 'default' | 'mono';
    SvgProps?: LogoSvgProps;
  }
>;

export type LogoSvgProps = React.ComponentPropsWithoutRef<'svg'> & {
  /** force monochrome */
  mono?: boolean;
};

const Logo = ({ homeLink, variant = 'default', SvgProps, ...props }: LogoProps) => {
  let SvgComponent;

  switch (variant) {
    case 'mono':
      SvgComponent = LogoSvg_Mono;
      break;

    default:
      SvgComponent = LogoSvg_Primary;
  }

  let inner = <SvgComponent {...mergePropsClassName(SvgProps, style.svg)} />;

  if (homeLink)
    inner = (
      <NextAnchor className={style.link} href="/">
        {inner}
      </NextAnchor>
    );

  return <Box {...mergeProps(props, { className: style.root })}>{inner}</Box>;
};

export default Logo;
