'use client';

import { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand';

export interface UnstyledAccordionOptions {
  active?: number[];
  multiple?: boolean;
  index?: number;
  //   openAll?: boolean;
}

interface UnstyledAccordionState extends UnstyledAccordionOptions {
  setActive: (index: number | number[]) => void;
  addActive: (index: number) => void;
  removeActive: (index: number) => void;
}

export type UnstyledAccordionStore = ReturnType<typeof createUnstyledAccordionStore>;

export const createUnstyledAccordionStore = (initProps?: Partial<UnstyledAccordionOptions>) => {
  return createStore<UnstyledAccordionState>()((set, get) => ({
    index: 0,
    active: [],
    setActive: (index) => {
      const multiple = get().multiple;
      const activeArr = Array.isArray(index) ? index : [index];
      const active = multiple ? activeArr : [activeArr[activeArr.length - 1]];

      set({ active });
    },
    addActive: (index) => {
      const active = get().active ?? [];
      if (active.indexOf(index) === -1) {
        active.push(index);
        set({ active });
      }
    },
    removeActive: (index) => {
      const active = get().active ?? [];
      const indexToRemove = active.indexOf(index);
      if (indexToRemove !== -1) {
        active.splice(indexToRemove, 1);
        set({ active });
      }
    },
    multiple: false,
    ...initProps,
  }));
};

export const UnstyledAccordionContext = createContext<UnstyledAccordionStore | null>(null);

export function useUnstyledAccordion<T>(selector: (state: UnstyledAccordionState) => T): T {
  const store = useContext(UnstyledAccordionContext);
  if (!store) throw new Error('Missing UnstyledAccordionContext.Provider in the tree');
  return useStore(store, selector);
}

export const useUnstyledAccordionItem = (index?: number) => {
  const { active, addActive, removeActive } = useUnstyledAccordion((state) => state);
  if (index === undefined) return { isActive: false, toggle: () => {} };

  const isActive = active?.indexOf(index) !== -1;
  const toggle = (force?: boolean) => {
    const newActive = force ?? !isActive;
    if (newActive) {
      addActive(index);
    } else {
      removeActive(index);
    }
  };
  return { isActive, toggle };
};
