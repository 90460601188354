'use client';

import { SEARCH_PARAM } from '@/components/EntryIndex/entryIndexConstants';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import SearchBarResult, { SearchBarResultProps } from '@/components/SearchBar/SearchBar.Result';
import Box, { BoxProps } from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { useNavigationEvents } from '@/lib/utils/useNavigationEvents';
import { useBreakpointMax } from '@/theme';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useNavBarActions } from '../NavBar/useNavBar';
import Btn from '../ui/Btn';
import * as styles from './SearchBar.css';
import { MIN_SEARCH_LENGTH } from './SearchBar.Input';

export type SearchBarResultsProps = BoxProps<
  'div',
  {
    SearchBarResultProps?: Omit<SearchBarResultProps, 'item'>;
    disableLoadStates?: boolean;
    disableOutboundLink?: boolean;
    disableDivider?: boolean;
  }
>;

const SearchBarResults = ({
  SearchBarResultProps,
  disableLoadStates = false,
  disableOutboundLink = false,
  disableDivider = false,
  ...props
}: SearchBarResultsProps) => {
  const router = useRouter();
  const lg = useBreakpointMax('lg');
  const { search: navSearch, setSearch, setMenu } = useNavBarActions();
  useNavigationEvents({ onNavigate: () => setSearch(false) });

  const t = useTranslations();
  const loading = useEntryIndex((s) => s.loading);
  const items = useEntryIndex((s) => s.items);
  const search = useEntryIndex((s) => s.search);
  const hasSearch = useEntryIndex((s) => s.search && s.search.length >= MIN_SEARCH_LENGTH);
  const hasResults = items.length > 0;
  const showLoading = loading && hasSearch;

  const handleAllClick = () => {
    !lg && setSearch(!navSearch);
    lg && setMenu(false);
    const viewAllUrl = `/search?${SEARCH_PARAM}=${search}`;
    router.push(viewAllUrl, {});
  };

  const TxtProps: TxtProps<'p'> = {
    as: 'p',
    variant: 'body',
    noMargin: true,
    cx: { pY: '3xs' },
  };

  return hasSearch ? (
    <Box
      cx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      {...props}>
      {hasResults &&
        items.map((item, i) => <SearchBarResult key={i} item={item} {...SearchBarResultProps} />)}
      {!disableLoadStates &&
        (showLoading ? (
          <Txt {...TxtProps}>{t('search.loading', { query: search })}</Txt>
        ) : hasResults ? (
          disableOutboundLink ? (
            <></>
          ) : (
            <Box>
              <Btn
                size="small"
                color="gum"
                className={styles.viewAllButton}
                onClick={handleAllClick}>
                {t('actions.viewAllResults')}
              </Btn>
            </Box>
          )
        ) : (
          <Txt {...TxtProps}>{t('search.noResults', { query: search })}</Txt>
        ))}
      {!disableDivider && <Divider className={styles.divider} />}
    </Box>
  ) : null;
};

export default SearchBarResults;
