'use client';

import { useTranslations } from 'next-intl';
import { useState } from 'react';
import Btn from '../ui/Btn';
import Divider from '../ui/Divider';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './TwoColumnContent.css';

type TwoColumnContent = FlexProps<
  'div',
  {
    heading?: string | null;
    items?: React.ReactNode[];
    limit?: boolean;
    maxItems?: number;
  }
>;

const MAX_ITEMS = 6;

const TwoColumnContent = ({
  items,
  heading,
  children,
  limit = true,
  maxItems = MAX_ITEMS,
  ...props
}: TwoColumnContent) => {
  const [count, setCount] = useState<number>(maxItems);
  const t = useTranslations('actions');

  const addCount = () => {
    setCount(count + maxItems);
  };

  const cards = limit ? items?.slice(0, count) : items;

  return (
    <Flex direction="column" rowGap="md" {...props}>
      {heading && (
        <Txt as="h5" variant="h5" noMargin>
          {heading}
        </Txt>
      )}
      {children || (
        <Flex direction="row" wrap columnGap="lg" rowGap="lg">
          {cards?.map(
            (card, i) =>
              card && (
                <Flex key={i} className={styles.card}>
                  {card}
                </Flex>
              )
          )}
          {/* Add a line if the item length is odd */}
          {(cards?.length ?? 0) % 2 !== 0 && (cards?.length ?? 0) > 1 && (
            <Flex className={styles.card}>
              <Divider />
            </Flex>
          )}
          {count < (items?.length ?? 0) && limit && (
            <Btn variant="nav" underline="always" onClick={addCount}>
              {t('viewMore')}
            </Btn>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default TwoColumnContent;
