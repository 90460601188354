'use client';

import { SvgIconProps, SvgIconTypeMap } from '@/components/ui';
import { SprinklesProps, useSprinklesProps } from '@/theme';

import ArrowLeft from '@mui/icons-material/ArrowBack';
import ArrowDown from '@mui/icons-material/ArrowDownward';
import Error from '@mui/icons-material/Error';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import Linkedin from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';

import ArrowRight from './CustomIcons/ArrowRight';
import ArrowUp from './CustomIcons/ArrowUp';
import ChevronDown from './CustomIcons/ChevronDown';
import ChevronUp from './CustomIcons/ChevronUp';
import Circle from './CustomIcons/Circle';
import CircleFilled from './CustomIcons/CircleFilled';
import Close from './CustomIcons/Close';
import Menu from './CustomIcons/Menu';
import Minus from './CustomIcons/Minus';
import OpenOutline from './CustomIcons/OpenOutline';
import Plus from './CustomIcons/Plus';
import Quotation from './CustomIcons/Quotation';
import Search from './CustomIcons/Search';
import Share from './CustomIcons/Share';

import { SvgIcon } from '@mui/material';
import * as style from './Icon.css';

export const appIcons = {
  error: Error,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  linkedin: Linkedin,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
} as const;

export const customIcons = {
  circle: Circle,
  circleFilled: CircleFilled,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  search: Search,
  openOutline: OpenOutline,
  plus: Plus,
  minus: Minus,
  arrowUp: ArrowUp,
  arrowRight: ArrowRight,
  close: Close,
  menu: Menu,
  share: Share,
  quotation: Quotation,
};

export const icons = { ...appIcons, ...customIcons } as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: style.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      <SvgIcon
        fontSize="inherit"
        // inheritViewBox={true} - Not used as it causes issues with some svgs
        viewBox="0 0 32 32"
        {...props}
        sx={sx}>
        <Component />
      </SvgIcon>
    );

  return <Component fontSize="inherit" {...props} sx={sx} />;
};

export default Icon;
