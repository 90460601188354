'use client';

import { SERVICE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { simplifyEntry } from '@/lib/utils/format';
import { withFragment } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, makeNonNullableArray, parseUri } from '@liquorice/utils';
import { gql } from '__generated__';
import Card from '../Card';
import Flex from '../ui/Flex';
import { OverrideCardProps } from './EntryCard';
import * as styles from './InsightCard.css';

import { PROFILE_CARD_FRAGMENT } from './ProfileCard';

export const INSIGHT_CARD_FRAGMENT = gql(`
  fragment insightCard on insight_Entry {
    id
    uri
    slug
    url
    postDate @formatDateTime(format: "j F Y")
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    authorMultiple {
      ...profileCard
    }
    serviceMultiple {
      ...serviceCard
    }
  }
`);

const InsightCard = withFragment(INSIGHT_CARD_FRAGMENT, (data, props: OverrideCardProps) => {
  if (!data) return null;

  const { entryImage, postDate, entryTitle, uri, authorMultiple, serviceMultiple } = data;
  const { heading } = props;

  const profiles = excludeEmptyTypedElements(authorMultiple);
  const services = excludeEmptyTypedElements(serviceMultiple);

  const maybeImage = makeNonNullableArray(props.image);
  const image = !!maybeImage.length ? maybeImage : entryImage;
  const href = parseUri(uri);

  const meta = simplifyEntry(PROFILE_CARD_FRAGMENT, profiles);
  const categories = simplifyEntry(SERVICE_CARD_FRAGMENT, services);

  return (
    <Card
      disableLinkWrap
      elevation={false}
      className={styles.root}
      fullHeight
      item={{
        title: heading ?? entryTitle,
        titleComponent: 'a',
        meta,
        categories,
        description: postDate as string,
        image,
        href,
      }}>
      <Card.Body cx={{ rowGap: 'sm', p: 'md' }}>
        <Flex rowGap="3xs">
          <Card.Description variant="xs" />
          <Card.Meta variant="xs" WrapperProps={{ wrap: true }} />
        </Flex>
        <Flex>
          <Card.Categories
            variant="outlined"
            color="gum"
            size="tiny"
            className={styles.categories}
            BoxProps={{ className: styles.categoryWrapper }}
          />
        </Flex>
        <Card.Title
          variant="h6"
          href={href}
          hover={false}
          className={styles.title}
          cx={{ decoration: 'none' }}
        />
        <Card.Cta className={styles.cta} as="a" href={href} />
      </Card.Body>
    </Card>
  );
});

export default InsightCard;
