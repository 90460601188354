import { useBreakpointMax } from '@/theme';
import { useTranslations } from 'next-intl';
import IconButton from '../IconButton';
import Box from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Divider from '../ui/Divider';
import Txt from '../ui/Txt';

export default function NavBarDrawerSearchButton(props: BtnProps) {
  const t = useTranslations('actions');
  const lg = useBreakpointMax('lg');

  return (
    lg && (
      <Box cx={{ mX: { xs: 'margin', md: 'gutter' } }}>
        <Divider />
        <Box cx={{ pY: 'xs' }}>
          <Btn variant="nav" underline="none" justify="start" {...props}>
            <Txt as="span" variant="lg">
              {t('search')}
            </Txt>
            <IconButton as="span" icon="search" variant="outlined" size="tiny" />
          </Btn>
        </Box>
      </Box>
    )
  );
}
