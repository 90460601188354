import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m12.418 3.58-8.84 8.84m8.84 0-8.84-8.84"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 16V0h16v16z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
