import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="#000"
      d="M11.583 10.618a1.91 1.91 0 0 0-1.38.59L5.77 8.714a1.912 1.912 0 0 0 0-.984l4.431-2.493a1.91 1.91 0 1 0-.47-.835L5.3 6.896a1.916 1.916 0 1 0 0 2.653l4.431 2.493a1.917 1.917 0 1 0 1.852-1.424Z"
    />
  </svg>
);
export default SvgComponent;
