'use client';

import {
  LOCATION_CATEGORY_FRAGMENT,
  PROFILE_ROLE_CATEGORY_FRAGMENT,
} from '@/gql/fragments/categories.gql';
import { SimplifiedEntry, simplifyCategory } from '@/lib/utils/format';
import { parseUri, toStringOrNull } from '@liquorice/utils';
import { ProfileEntryFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import React from 'react';
import ImageAsset from '../ImageAsset';
import Btn from '../ui/Btn';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import VcardAsset from '../vCard';

const Profile = ({
  entryTitle,
  entryImage,
  phoneNumber,
  officeNumber,
  email,
  vCard,
  locationCategory,
  profileRoleCategory,
  externalUrl,
}: ProfileEntryFragment) => {
  const t = useTranslations('social');
  const content: (string | null | undefined)[] = [phoneNumber?.number, officeNumber?.number];
  const linkedinHref = toStringOrNull(externalUrl);
  const role = simplifyCategory(PROFILE_ROLE_CATEGORY_FRAGMENT, profileRoleCategory);
  const location = simplifyCategory(LOCATION_CATEGORY_FRAGMENT, locationCategory);

  const details = [...role, ...location];
  const allUndefined = content.every((item) => item === undefined);

  return (
    <Flex
      columnGap="md"
      justifyContent="start"
      cx={{
        direction: { xs: 'column', md: 'row', lg: 'column' },
        alignItems: { md: 'center', lg: 'start' },
      }}>
      <ImageAsset data={entryImage} />
      <Flex rowGap="4xs">
        <Txt as="h5" variant="h5" noMargin cx={{ mT: 'sm' }}>
          {entryTitle}
        </Txt>
        {details.length > 0 && (
          <Flex direction="row" columnGap="3xs">
            {details.map((item: SimplifiedEntry, i: number) => {
              return (
                <React.Fragment key={i}>
                  <Txt as="span" variant="sm" color="black">
                    {item.title}
                  </Txt>
                  {i !== details.length - 1 && (
                    <Divider direction="vertical" color="accent" style={{ height: '1em' }} />
                  )}
                </React.Fragment>
              );
            })}
          </Flex>
        )}
        <Flex alignItems="start" rowGap="4xs">
          {!allUndefined && (
            <Flex direction="row" columnGap="3xs">
              {content.map((item, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <Btn
                      as="a"
                      variant="text"
                      underline="none"
                      color="black"
                      href={parseUri(`tel:${item}`)}>
                      {item}
                    </Btn>
                    {i !== content.length - 1 && (
                      <Divider direction="vertical" color="accent" style={{ height: '1em' }} />
                    )}
                  </React.Fragment>
                );
              })}
            </Flex>
          )}
          {email && (
            <Btn variant="text" size="small" underline="none" as="a" href={'mailto:' + email}>
              {email}
            </Btn>
          )}
          <Flex direction="row" columnGap="xs">
            <VcardAsset size="small" data={vCard} />
            {linkedinHref && (
              <Btn
                size="small"
                href={linkedinHref}
                startIcon="linkedin"
                variant="text"
                underline="none"
                target="_blank">
                {t('linkedin')}
              </Btn>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Profile;
