import { PROFILE_ROLE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { simplifyCategory } from '@/lib/utils/format';
import { parseUri } from '@liquorice/utils';
import { ProfileCardFragment } from '__generated__/graphql';
import ImageAsset from '../ImageAsset';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';

type KeyContactItemsProps = {
  items: ProfileCardFragment[];
  minItems?: number;
  maxItems?: number;
};

const KeyContactItems = ({ items, minItems = 0, maxItems = 6 }: KeyContactItemsProps) => {
  return items.slice(minItems, maxItems).map((profile) => {
    const roles = simplifyCategory(PROFILE_ROLE_CATEGORY_FRAGMENT, profile?.profileRoleCategory);

    return (
      <Flex
        key={profile.id}
        as="a"
        href={parseUri(profile.uri)}
        direction="row"
        columnGap="2xs"
        alignItems="center"
        cx={{ decoration: 'none' }}>
        <Flex cx={{ width: { xs: '30', lg: '25', xl: '20' } }}>
          <ImageAsset ratio="SQUARE" round data={profile.entryImage} />
        </Flex>
        <Flex direction="row" alignItems="center" columnGap="4xs" wrap cx={{ width: '100' }}>
          <Txt variant="body" noMargin color="black">
            {profile?.entryTitle}
          </Txt>
          <Divider direction="vertical" style={{ height: '0.7em' }} />
          {roles?.map((role) => {
            return (
              <Txt key={role.id} variant="body" noMargin color="black">
                {role.title}
              </Txt>
            );
          })}
        </Flex>
      </Flex>
    );
  });
};
export default KeyContactItems;
