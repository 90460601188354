'use client';

import Txt, { TxtProps } from '../ui/Txt';
import { PageHeaderRootProps } from './PageHeaderRoot';

export type PageTitleProps = TxtProps<'h1'> & Partial<PageHeaderRootProps>;

export default function PageTitle({ children, ...props }: PageTitleProps) {
  return (
    <Txt as="h1" variant="h1" noMargin {...props}>
      {children}
    </Txt>
  );
}
