'use client';

import { useTranslations } from 'next-intl';
import IconButton, { IconButtonProps } from '../IconButton';
import Box, { BoxProps } from '../ui/Box';

export type TopButtonProps = Omit<IconButtonProps, 'icon'> & { BoxProps?: BoxProps };

const TopButton = ({ BoxProps, ...props }: TopButtonProps) => {
  const t = useTranslations('common');

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box {...BoxProps}>
      <IconButton
        icon="arrowUp"
        size="small"
        onClick={handleScrollToTop}
        aria-label={t('top')}
        variant="outlined"
        {...props}
      />
    </Box>
  );
};

export default TopButton;
