import { create } from 'zustand';

export type PageHeaderResponsiveVariant = 'mobile' | 'desktop';

export type PageHeader = {
  fallbackHeight: number;
  height?: number | undefined;
  setHeight: (height: number | undefined) => void;
};

export const usePageHeader = create<PageHeader>()((set) => ({
  fallbackHeight: 588,
  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),
}));
