'use client';

import {
  LOCATION_CATEGORY_FRAGMENT,
  PROFILE_ROLE_CATEGORY_FRAGMENT,
} from '@/gql/fragments/categories.gql';
import { simplifyCategory } from '@/lib/utils/format';
import { withFragment } from '@liquorice/gql-utils';
import { makeNonNullableArray, parseUri } from '@liquorice/utils';
import { gql } from '__generated__';
import { flatMap } from 'lodash';
import Card from '../Card';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import { OverrideCardProps } from './EntryCard';

export const PROFILE_CARD_FRAGMENT = gql(`
  fragment profileCard on profile_Entry {
    id
    uri
    slug
    url
    entryTitle
    entrySummary
    phoneNumber {
      number
    }
    officeNumber {
      number
    }
    displayProfile
    email
    entryImage {
      ...ImageAsset
    }
    locationCategory {
      ...locationCategory
    }
    profileRoleCategory {
      ...profileRoleCategory
    }
  }
`);

const ProfileCard = withFragment(PROFILE_CARD_FRAGMENT, (data, props: OverrideCardProps) => {
  if (!data) return null;

  const {
    entryImage,
    entryTitle,
    uri,
    phoneNumber,
    officeNumber,
    email,
    locationCategory,
    profileRoleCategory,
  } = data;

  const { heading } = props;
  const href = parseUri(uri);
  const role = simplifyCategory(PROFILE_ROLE_CATEGORY_FRAGMENT, profileRoleCategory);
  const location = simplifyCategory(LOCATION_CATEGORY_FRAGMENT, locationCategory);
  const maybeImage = makeNonNullableArray(props.image);
  const image = !!maybeImage?.length ? maybeImage : entryImage;
  const meta = flatMap([role, location]);

  return (
    <Card
      elevation={false}
      disableLinkWrap
      item={{
        title: heading ?? entryTitle,
        image,
        href,
        phone: phoneNumber?.number,
        meta,
        phoneNumber: phoneNumber?.number,
        officeNumber: officeNumber?.number,
        email,
      }}>
      <Box as="a" href={href}>
        <Card.Image ratio="SQUARE" />
      </Box>
      <Card.Body>
        <Card.Title as="a" href={href} variant="h6" cx={{ mT: 'md', decoration: 'none' }} />
        <Card.Meta variant="sm" WrapperProps={{ cx: { mB: 'xs' } }} />
        <Flex rowGap="5xs">
          <Card.PhoneNumber variant="xs" />
          <Card.OfficeNumber variant="xs" />
          <Card.Email enableLinkWrap variant="xs" />
        </Flex>
      </Card.Body>
    </Card>
  );
});

export default ProfileCard;
