'use client';

import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { A11y, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide, useSwiper } from 'swiper/react';
import IconButton from '../IconButton';
import Box from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import Txt from '../ui/Txt';
import * as styles from './Carousel.css';

type CarouselNavigationProps = FlexProps<
  'div',
  {
    slides: unknown[];
    activeIndex: number;
  }
>;

export const CarouselNavigation = ({ slides, activeIndex, ...props }: CarouselNavigationProps) => {
  const swiper = useSwiper();

  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };

  return (
    <Flex direction="row" columnGap="sm" rowGap="sm" {...props}>
      <IconButton icon="chevronLeft" variant="outlined" color="gum" onClick={handlePrev} />
      <Txt>{activeIndex + 1 + '/' + slides.length}</Txt>
      <IconButton icon="chevronRight" variant="outlined" color="gum" onClick={handleNext} />
    </Flex>
  );
};

type CarouselPaginationProps = FlexProps<
  'div',
  {
    slides: unknown[];
    activeIndex: number;
  }
>;

export const CarouselPagination = ({ slides, activeIndex, ...props }: CarouselPaginationProps) => {
  const swiper = useSwiper();

  const handleCurrent = (index: number) => {
    swiper.slideTo(index);
  };

  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };

  return (
    <Flex direction="row" alignItems="center" {...props}>
      <IconButton
        icon="chevronLeft"
        variant="nav"
        underline="none"
        color="gum"
        onClick={handlePrev}
      />
      {slides.map((_, i) => (
        <IconButton
          key={`slide ${i + 1}`}
          icon={activeIndex === i ? 'circleFilled' : 'circle'}
          color="gum"
          variant="nav"
          underline="none"
          role="button"
          aria-label={`slide ${i + 1}`}
          onClick={() => handleCurrent(i)}
        />
      ))}
      <IconButton
        icon="chevronRight"
        variant="nav"
        underline="none"
        color="gum"
        onClick={handleNext}
      />
    </Flex>
  );
};

export type CarouselProps = {
  items?: (JSX.Element | undefined)[];
  NavigationProps?: {
    hideNavigation?: boolean;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
  } & FlexProps;
  PaginationProps?: { hidePagination?: boolean } & FlexProps;
} & SwiperProps;

const Carousel = ({ items = [], NavigationProps, PaginationProps, ...props }: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { hideNavigation, GridProps, GridColProps, ...navigationProps } = NavigationProps ?? {};
  const { hidePagination = true, ...paginationProps } = PaginationProps ?? {};

  if (!items.length) return <></>;

  return (
    <Swiper
      className={styles.swiper}
      modules={[Pagination, A11y]}
      slidesPerView={1}
      slidesPerGroup={1}
      onSlideChange={(s) => setActiveIndex(s.activeIndex)}
      {...props}>
      {items?.map((item, i) => (
        <SwiperSlide key={i}>
          <Box>{item}</Box>
        </SwiperSlide>
      ))}
      {!hideNavigation && (
        <Grid {...GridProps}>
          <Grid.Col {...GridColProps}>
            <CarouselNavigation activeIndex={activeIndex} slides={items} {...navigationProps} />
          </Grid.Col>
        </Grid>
      )}
      {!hidePagination && (
        <CarouselPagination activeIndex={activeIndex} slides={items} {...paginationProps} />
      )}
    </Swiper>
  );
};

export default Carousel;
