import { SimplifiedEntry } from '@/lib/utils/format';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { parseUri } from '@liquorice/utils';
import { isArray } from 'lodash';
import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardCategoriesProps<D extends React.ElementType = 'button'> = BtnProps<
  D,
  { BoxProps?: BoxProps; disableLinkWrap?: boolean }
>;

export const CardCategories = <D extends React.ElementType>({
  children,
  BoxProps,
  disableLinkWrap,
  ...props
}: CardCategoriesProps<D>) => {
  const { categories } = useCard();
  const content = children ?? categories;

  if (!content) return null;

  return isArray(content) ? (
    content.length > 0 ? (
      <Flex direction="row" columnGap="3xs" rowGap="3xs" wrap>
        {content.map((item: SimplifiedEntry, idx: number) => {
          const customButtonProps: BtnProps = {
            ...(!disableLinkWrap ? { href: parseUri(item.uri) ?? '' } : {}),
            variant: 'filled',
            size: 'small',
            ...mergePropsClassName(props, style.categories),
          };
          return (
            <Box key={idx} {...BoxProps}>
              <Btn {...customButtonProps}>{item.title}</Btn>
            </Box>
          );
        })}
      </Flex>
    ) : null
  ) : (
    <Btn variant="filled" size="small" {...mergePropsClassName(props, style.categories)}>
      {content}
    </Btn>
  );
};
