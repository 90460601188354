import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 27" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m29.1.85-.75 5.2c-1.533-.133-2.75.133-3.65.8-.9.633-1.517 1.55-1.85 2.75-.3 1.167-.35 2.5-.15 4h6.4v12.85H16.75V13.1c0-4.367 1.033-7.667 3.1-9.9C21.917.933 25 .15 29.1.85Zm-16.75 0-.75 5.2c-1.533-.133-2.75.133-3.65.8-.9.633-1.517 1.55-1.85 2.75-.3 1.167-.35 2.5-.15 4h6.4v12.85H0V13.1c0-4.367 1.033-7.667 3.1-9.9C5.167.933 8.25.15 12.35.85Z"
    />
  </svg>
);
export default SvgComponent;
