'use client';

import { useBreakpointMax } from '@/theme';
import { excludeEmptyTypedElements, firstNonNullable, parseUri } from '@liquorice/utils';
import { ArticleCardFragment, InsightCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Authors from '../Authors';
import CtaButton from '../CtaButton';
import ImageAsset from '../ImageAsset';
import ServiceButtons from '../ServiceButtons';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';

const FeaturedCard = (props: ArticleCardFragment | InsightCardFragment) => {
  const { entryImage, postDate, serviceMultiple, entryTitle, authorMultiple, uri } = props;
  const services = excludeEmptyTypedElements(serviceMultiple);
  const authors = excludeEmptyTypedElements(authorMultiple);
  const image = firstNonNullable(entryImage);
  const href = parseUri(uri);

  const t = useTranslations('actions');

  const sm = useBreakpointMax('sm');

  return (
    <Grid rowGutter>
      <Grid.Col lg={5}>
        <ImageAsset ratio={sm ? 'LANDSCAPE_WIDE' : 'LANDSCAPE'} data={image} />
      </Grid.Col>
      <Grid.Col
        offsetLg={1}
        lg={6}
        cx={{ display: 'flex', direction: 'column', justifyContent: 'center', rowGap: 'sm' }}>
        <Txt as="h3" variant="h4" noMargin>
          {entryTitle}
        </Txt>
        <Flex rowGap="5xs">
          <Txt variant="xs">{postDate}</Txt>
          <Authors variant="xs" data={authors} />
        </Flex>
        <Flex direction="row" rowGap="xs" columnGap="xs" wrap>
          {services.map((service, i) => {
            return <ServiceButtons key={i} data={service} isLink />;
          })}
        </Flex>
        {href && (
          <Box>
            <CtaButton underline="always" size="large" href={href} endIcon={undefined}>
              {t('readMore')}
            </CtaButton>
          </Box>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default FeaturedCard;
