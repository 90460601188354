'use client';

import { useAppContext } from '@/lib/context/hooks';
import { makeNonNullableArray } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Divider from '../ui/Divider';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';

type QuickLinks = FlexProps<
  'div',
  {
    heading?: string;
    isSideBar?: boolean;
  }
>;

export default function QuickLinks({ heading, isSideBar, ...props }: QuickLinks) {
  const t = useTranslations('common');
  const { quickLinks } = useAppContext() ?? {};

  const content = heading ?? t('onThisPage');

  if (!quickLinks) return null;

  const items = makeNonNullableArray(quickLinks);

  return (
    <Flex rowGap="sm" {...props}>
      <Box>
        <Txt as="p" variant="h6">
          {content}
        </Txt>
        {isSideBar && <Divider />}
      </Box>
      <Flex rowGap="2xs">
        {items?.map((item) => {
          return (
            <Box key={item.id}>
              <Btn variant="text" justify="start" wrap underline="always" href={item.href}>
                {item.heading}
              </Btn>
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
}
