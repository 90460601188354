'use client';

import { ENTRY_CARD_FRAGMENTS } from '@/gql/fragments/entryCards.gql';
import { withFragment } from '@liquorice/gql-utils';
import { parseUri } from '@liquorice/utils';
import Card from '../Card';
import { useEntryCards } from '../Entry/useEntryCards';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';

const SearchCard = withFragment(ENTRY_CARD_FRAGMENTS, (data) => {
  const entryCard = useEntryCards(data);

  const title = entryCard?.fragment?.entryTitle;
  const description = entryCard?.fragment?.entrySummary;
  const href = parseUri(entryCard?.fragment?.uri);
  const categories = entryCard?.typeLabel;

  return (
    <Card
      elevation={false}
      item={{
        title,
        description,
        categories,
        href,
      }}>
      <Card.Body cx={{ rowGap: 'xs', mY: 'md' }}>
        <Card.Meta variant="sm" />
        <Flex direction="row" alignItems="center" spacing="sm">
          <Card.Title variant="h5" as="span" />
          <Box>
            <Card.Categories variant="outlined" color="gum" />
          </Box>
        </Flex>
        <Card.Description variant="body" />
      </Card.Body>
      <Divider direction="horizontal" cx={{ color: 'black' }} />
    </Card>
  );
});

export default SearchCard;
