import { ListElement } from '@/lib/parsers/common/parseElementList';
import { CategoryRelationCriteriaInput, EntryRelationCriteriaInput } from '__generated__/graphql';
import {
  EntryIndexActions,
  EntryIndexState,
  EntryIndexStateWithActions,
  EntryIndexVariables,
  FilterSliceState,
} from './entryIndexTypes';
import { DEFAULT_PER_PAGE } from './useEntryIndex';

export const selectIndexQueryVariables = (state: EntryIndexState): EntryIndexVariables => {
  const { search, id, filters, filterSelections, baseEntryQuery, perPage, page = 1 } = state;

  const limit = perPage ?? baseEntryQuery.limit ?? DEFAULT_PER_PAGE;
  const hasSearch = !!search;

  const queryVariables: EntryIndexVariables = {
    ...baseEntryQuery,
    search: search ?? undefined,
    id,
    // If there is a search, order by score
    orderBy: hasSearch ? 'score DESC' : baseEntryQuery.orderBy,
    limit,
    offset: (page - 1) * limit,
  };

  const relatedToCategories: CategoryRelationCriteriaInput[] = [];
  const relatedToEntries: EntryRelationCriteriaInput[] = [];

  Object.entries(filters).forEach(([filterId, filter]) => {
    const { relation, type } = filter;
    const selection = filterSelections[filterId] ?? [];

    if (type === 'entryTypeSection' && selection.length > 0) {
      queryVariables.section = selection;
      return;
    }

    if (selection.length === 0) return;

    const filterItem = { id: [relation, ...selection] };

    if (type === 'category') {
      relatedToCategories.push(filterItem);
    } else if (type === 'entry') {
      relatedToEntries.push(filterItem);
    }
  });

  if (relatedToCategories.length > 0) {
    queryVariables.relatedToCategories = relatedToCategories;
  }

  if (relatedToEntries.length > 0) {
    queryVariables.relatedToEntries = relatedToEntries;
  }

  return queryVariables;
};

export const selectFilterSlice =
  (sliceId: string) =>
  (state: EntryIndexState): FilterSliceState | undefined =>
    state.filters[sliceId];

export const selectIsFiltered = (state: EntryIndexState): boolean => {
  return (
    !!state.search ||
    Object.values(state.filterSelections).some((selection) => !!selection && selection.length > 0)
  );
};

export const selectActiveFilters = (state: EntryIndexState) => {
  return Object.entries(state.filterSelections).reduce((acc, [filterId, selection]) => {
    if (!selection?.length) return acc;

    const allFilterItems = state.filters[filterId].items ?? [];
    const selectionsArr = allFilterItems.filter(({ id }) => selection.includes(id));
    return [...acc, ...selectionsArr];
  }, [] as ListElement[]);
};

export const selectActions = (state: EntryIndexStateWithActions): EntryIndexActions => {
  const {
    setBaseEntryQuery,
    setState,
    setSearch,
    setItems,
    resetFilters,
    setFilterSelections,
    filterSelect,
    filterRemove,
    filterClear,
    setLoading,
    setError,
    setInitialLoaded,
    setPage,
    setTotalItems,
  } = state;
  return {
    setBaseEntryQuery,
    setState,
    setSearch,
    setItems,
    resetFilters,
    setFilterSelections,
    filterSelect,
    filterRemove,
    filterClear,
    setLoading,
    setError,
    setInitialLoaded,
    setPage,
    setTotalItems,
  };
};
