import { ENTRY_CARD_FRAGMENTS } from '@/gql/fragments/entryCards.gql';
import { withFragment } from '@liquorice/gql-utils';
import { MaybeImage } from '../ImageAsset';
import ArticleCard from './ArticleCard';
import ArticleIndexCard from './ArticleIndexCard';
import InsightCard from './InsightCard';
import OverviewCard from './OverviewCard';
import ProfileCard from './ProfileCard';
import ProfileIndexCard from './ProfileIndexCard';
import StandardCard from './StandardCard';

export type OverrideCardProps = {
  heading?: string | null;
  content?: string | null;
  image?: MaybeImage;
};

const EntryCard = withFragment(ENTRY_CARD_FRAGMENTS, (data) => {
  if (data?.__typename === 'profile_Entry') {
    return <ProfileCard data={data} />;
  }
  if (data?.__typename === 'article_Entry') {
    return <ArticleCard data={data} />;
  }
  if (data?.__typename === 'overview_Entry') {
    return <OverviewCard data={data} />;
  }
  if (data?.__typename === 'insight_Entry') {
    return <InsightCard data={data} />;
  }
  if (data?.__typename === 'profileIndex_Entry') {
    return <ProfileIndexCard data={data} />;
  }
  if (data?.__typename === 'articleIndex_Entry') {
    return <ArticleIndexCard data={data} />;
  }
  if (data?.__typename === 'standard_Entry') {
    return <StandardCard data={data} />;
  }
});

export default EntryCard;
