'use client';

import { CommonLinks } from '@/lib/getters/getCommonLinks';
import { EntriesFragment } from '__generated__/graphql';
import { createContext, ReactNode, useState } from 'react';

export type Anchor = {
  id: string;
  heading: string;
  href: string;
};

export type AppContext = {
  data: EntriesFragment | null;
  commonLinks: CommonLinks | null;
  quickLinks: Anchor[] | null;
  setQuickLinks: (anchor: Anchor[] | null) => void; // Setter function for quickLinks
  addQuickLink: (anchor: Anchor) => void; // New function to add a quickLink
};

const initialState: Omit<AppContext, 'setQuickLinks' | 'addQuickLink'> = {
  data: null,
  commonLinks: null,
  quickLinks: null,
};

export const appContext = createContext<AppContext | undefined>(undefined);

export const EntryProvider = ({
  children,
  value: inheritedValue,
}: {
  children: ReactNode;
  value: Partial<AppContext>;
}) => {
  const [quickLinks, setQuickLinks] = useState<Anchor[] | null>(null);

  const addQuickLink = (newAnchor: Anchor) => {
    setQuickLinks((prevQuickLinks) => {
      if (prevQuickLinks) {
        const isDuplicate = prevQuickLinks.some((anchor) => anchor.id === newAnchor.id);
        if (isDuplicate) {
          // console.log('Quick link with this ID already exists:', newAnchor.id);
          return prevQuickLinks; // Do nothing if it's a duplicate
        }
        return [...prevQuickLinks, newAnchor]; // Append the new link if not a duplicate
      } else {
        return [newAnchor]; // Initialize if quickLinks was null
      }
    });
  };

  const value: AppContext = {
    ...initialState,
    ...inheritedValue,
    quickLinks,
    setQuickLinks, // Provide the setter in the context
    addQuickLink,
  };

  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};
export default EntryProvider;
