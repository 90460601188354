import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import Btn, { BtnComponentWithRef } from '../ui/Btn';
import * as styles from './CtaButton.css';

const CtaButton: BtnComponentWithRef = React.forwardRef(function CtaButton(
  { children, ...props },
  ref
) {
  const t = useTranslations('actions');

  const content = children || t('viewMore');

  return (
    <Btn
      ref={ref}
      variant="nav"
      endIcon="arrowRight"
      underline="hover"
      {...props}
      {...mergePropsClassName(props, styles.root)}>
      {content}
    </Btn>
  );
});

export default CtaButton;
