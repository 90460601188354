import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.958 7.48v5.15a1.119 1.119 0 0 1-1.12 1.12H4.12A1.12 1.12 0 0 1 3 12.63V5.911a1.12 1.12 0 0 1 1.12-1.12h4.688M10.615 3h3.135v3.135M7.48 9.271l6.046-6.047"
    />
  </svg>
);
export default SvgComponent;
