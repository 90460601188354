import { Color, colorSetVariants } from '@/theme/partials/palette.css';

export type ColorScheme =
  | {
      __typename: 'colorScheme';
      label?: string | null;
    }
  | null
  | undefined;

export const parseColorScheme = (data: Maybe<ColorScheme>): Color | undefined => {
  const label = data?.label;

  return label && label in colorSetVariants ? (label as Color) : undefined;
};
