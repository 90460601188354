import { PROFILE_CARD_FRAGMENT } from '@/components/EntryCards';
import { SERVICE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { TypedDocumentNode } from '@liquorice/utils';
import { FragmentType } from '__generated__';
import {
  ContentTypeCategoryFragment,
  LocationCategoryFragment,
  ProfileCardFragment,
  ProfileRoleCategoryFragment,
  ServiceCardFragment,
} from '__generated__/graphql';

export const fmtFullName = (
  honorific?: string | null,
  firstName?: string | null,
  lastName?: string | null
) => {
  if (!firstName || !lastName) return null;

  return `${honorific ?? ''} ${firstName ?? ''} ${lastName ?? ''}`;
};

type EntryCards = ProfileCardFragment | ServiceCardFragment;

type EntryCardFragments =
  | FragmentType<typeof SERVICE_CARD_FRAGMENT>
  | FragmentType<typeof PROFILE_CARD_FRAGMENT>;

export type SimplifiedEntry = {
  title: string;
  id: string;
  uri: string;
};

export const simplifyEntry = (
  fragment: TypedDocumentNode<EntryCards, unknown>,
  data: MaybeArrayOf<EntryCardFragments>,
  exclude?: MaybeArrayOf<any>
) => {
  const parser = createFragmentArrayParser(fragment, (data) => data);

  const items = parser(data);
  const excludeItems = parser(exclude);

  const excludeIds = makeNonNullableArray(excludeItems).map((v) => v.id);
  const cats = makeNonNullableArray(items).filter((v) => !excludeIds.includes(v.id));

  return cats.map((v) => {
    return {
      title: v.entryTitle,
      id: v.id,
      uri: v.uri,
    } as SimplifiedEntry;
  });
};

type Categories =
  | ProfileRoleCategoryFragment
  | ContentTypeCategoryFragment
  | LocationCategoryFragment;

export const simplifyCategory = (
  fragment: TypedDocumentNode<Categories, unknown>,
  data: MaybeArrayOf<any>
) => {
  const parseCategories = createFragmentArrayParser(fragment, (data) => data);
  const items = parseCategories(data);

  return items.map((v) => {
    return {
      title: v.title,
      id: v.id,
      uri: v.uri,
    } as SimplifiedEntry;
  });
};
