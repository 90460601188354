'use client';

import { CommonLink } from '@/lib/getters/getCommonLinks';
import { ProfileCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import { KeyContactsProps } from './KeyContacts';
import KeyContactsCta from './KeyContacts.Cta';
import KeyContactItems from './KeyContacts.Items';

const KeyContactsWrap = ({
  type,
  id,
  data,
  profileIndex,
  optionalMax,
}: KeyContactsProps & { data: ProfileCardFragment[]; profileIndex?: CommonLink | null }) => {
  const defaultMax = type === 'article' || type === 'insight' ? 20 : 6;

  const maxItems = optionalMax ?? defaultMax;

  const t = useTranslations();

  if (!data.length) return null;

  return (
    <Box cx={{ mY: '2xl' }}>
      <Txt as="p" variant="h6">
        {t('common.keyContacts')}
      </Txt>
      <Divider cx={{ mB: 'xs' }} />
      <Flex rowGap="xs" cx={{ mB: 'xs' }}>
        <KeyContactItems items={data} maxItems={maxItems} />
      </Flex>
      <KeyContactsCta {...{ type, id, items: data, indexUri: profileIndex?.uri }}>
        {t('actions.viewAll')}
      </KeyContactsCta>
    </Box>
  );
};

export default KeyContactsWrap;
