import Btn from '@/components/ui/Btn';
import Txt from '@/components/ui/Txt';
import { NavMenuItem } from '@/lib/parsers/navigation';
import { useNavBarActions } from '../useNavBar';

export default function NavBarChild({
  title,
  type,
  subItems,
  newWindow,
  external,
  parentId,
  ...props
}: NavMenuItem) {
  const { menu, setMenu } = useNavBarActions();

  const toggleMenu = () => {
    setMenu(!menu);
  };

  return (
    <Btn variant="nav" underline="none" onClick={toggleMenu} {...props}>
      <Txt as="span" variant="body">
        {title}
      </Txt>
    </Btn>
  );
}
