import BasicContent from '@/components/BasicContent';
import { createBlock } from '@/lib/parsers/blocks/createBlock';
import { gql } from '__generated__';

const GENERIC_CONTENT_BLOCK = gql(`
  fragment genericContentBlock on blocks_genericContent_BlockType {
    heading
    content: htmlContentSimple
  }   
`);

const GenericContentBlock = createBlock(GENERIC_CONTENT_BLOCK, ({ data }) => {
  return <BasicContent {...data} />;
});

export default GenericContentBlock;
