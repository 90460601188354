'use client';

import EntryIndexNoResults from '@/components/EntryIndex/EntryIndex.NoResults';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import SkeletonCards, { SkeletonCardsProps } from '@/components/SkeletonCards';
import EntryCard from '../EntryCards/EntryCard';
import Grid, { GridColProps, GridProps } from '../ui/Grid';

export type EntryIndexResultsProps = {
  GridProps?: GridProps;
  GridColProps?: GridColProps;
  SkeletonCardsProps?: SkeletonCardsProps;
  Component?: React.ElementType;
};

/**
 * This component is responsible for rendering the entry index results
 * or no-results message
 */
const EntryIndexResults = ({
  SkeletonCardsProps,
  GridProps,
  GridColProps,
  Component,
}: EntryIndexResultsProps) => {
  const loading = useEntryIndex((s) => s.loading);
  const initialLoaded = useEntryIndex((s) => s.initialLoaded);
  const items = useEntryIndex((s) => s.items);

  const hasResults = items.length > 0;
  const showLoading = loading && !initialLoaded;

  const ResultComponent = Component || EntryCard;

  const skeletonCardsProps = {
    amount: 14,
    SkeletonItems: {
      items: [
        { variant: 'rounded', height: 175 },
        { variant: 'rounded', height: 30 },
        { variant: 'rounded', height: 30, width: '50%' },
      ],
    },
    ItemProps: {
      md: 6,
      lg: 3,
    },
    ...SkeletonCardsProps,
  } as SkeletonCardsProps;

  return (
    <>
      {showLoading && <SkeletonCards {...skeletonCardsProps} />}
      {hasResults ? (
        <Grid cx={{ rowGap: '2xl' }} {...GridProps}>
          {items.map((item, i) => {
            return (
              <Grid.Col key={i} md={6} lg={3} {...GridColProps}>
                <ResultComponent data={item} />
              </Grid.Col>
            );
          })}
        </Grid>
      ) : (
        initialLoaded && <EntryIndexNoResults />
      )}
    </>
  );
};

export default EntryIndexResults;
