'use client';

import { useEntryIndexSearchForm } from '@/components/EntryIndex/EntryIndex.SearchForm';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useEntryIndexQueryHandler } from '@/components/EntryIndex/useEntryIndexQueryHandler';
import useEntryIndexSubmitButton from '@/components/EntryIndex/useEntryIndexSubmitButton';
import IconButton, { IconButtonProps } from '@/components/IconButton';
import Box, { BoxProps } from '@/components/ui/Box';
import { BtnProps } from '@/components/ui/Btn';
import { mergePropsClassName } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import React from 'react';
import { useNavBarActions } from '../NavBar/useNavBar';
import Flex, { FlexProps } from '../ui/Flex';
import * as styles from './SearchBar.css';

export type SearchBarInputProps = BoxProps<
  'form',
  {
    InputProps?: BoxProps<'input'>;
    IconWrapperProps?: FlexProps;
    SearchIconProps?: Omit<IconButtonProps, 'icon'>;
    CloseIconProps?: Omit<IconButtonProps, 'icon'>;
  }
>;

export const MIN_SEARCH_LENGTH = 2;

const SearchBarInput = ({
  InputProps,
  IconWrapperProps,
  SearchIconProps,
  CloseIconProps,
  cx,
  ...props
}: SearchBarInputProps) => {
  const { setSearch } = useNavBarActions();
  const t = useTranslations('search');
  const placeholder = t('placeholder');
  const { inputProps, /* searchSummary, */ formProps } = useEntryIndexSearchForm();
  const submitButtonProps = useEntryIndexSubmitButton();
  const setSearchValue = useEntryIndex((s) => s.setSearch);
  const searchValue = useEntryIndex((s) => s.search);
  const setLoading = useEntryIndex((s) => s.setLoading);
  const clearItems = useEntryIndex((s) => () => s.setItems([]));
  const { triggerQuery } = useEntryIndexQueryHandler();

  // Create a 0.5s delay before updating the search value to wait for the user to finish typing
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  React.useEffect(() => {
    // if ((searchValue || '') === inputValue) return; // Prevent infinite loop
    const query = searchValue || '';
    if (timer) clearTimeout(timer);

    if (query.length === 0) {
      clearItems();
      return;
    }

    if (query.length < MIN_SEARCH_LENGTH) return; // Wait for at least 3 characters

    const newTimer = setTimeout(() => {
      triggerQuery(true);
    }, 500);

    // Set a new timeout
    setTimer(newTimer);
    setLoading(true);
    return () => {
      if (timer) {
        clearTimeout(timer);
        setLoading(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <Box
      as="form"
      {...formProps}
      cx={{
        pT: 'xs',
        pB: 'xs',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        ...cx,
      }}
      {...mergePropsClassName(props, styles.inputWrap)}>
      <Box
        as="input"
        cx={{ fontSize: 'md' }}
        {...inputProps}
        onChange={handleChange}
        value={searchValue || ''}
        {...mergePropsClassName(InputProps, styles.input)}
        placeholder={placeholder}
      />
      <Flex direction="row" columnGap="2xs" {...IconWrapperProps}>
        <IconButton
          icon="search"
          variant="outlined"
          {...mergePropsClassName(SearchIconProps, styles.iconButton)}
          {...(submitButtonProps as BtnProps)}
        />
        <IconButton
          icon="close"
          variant="outlined"
          {...mergePropsClassName(CloseIconProps, styles.iconButton)}
          onClick={() => setSearch(false)}
        />
      </Flex>
    </Box>
  );
};

export default SearchBarInput;
