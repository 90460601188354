import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63VW2vCMBgG4Ht%2FRaA3ClVyaJI2u5rO%2FI3Rg3WbzmyZuhP%2B95FuOj%2BVDwISyMXblyftRyHGO7cm3z1ChsN7vdh4Lqghfl6VfS5ESnimwyZSQkdaDm6Oi2xfzFToyJRwyS4V%2Bb4oeSiyv%2B28KA5FsS8KdamYHd6xCMVwvpSH4q5nzj9LGpJQSgGjDEnsbVgg1oYk0zwsEOeGJG3bgqwIqpZjqUBcBqGw2goQV%2BE8ZaUdg7gO8cQyy0DchPjOsukExLNgW6usBXFrSDLjtPvEXW%2F0ly7gEOaGbEvf%2Fx8KHOvDyeN8cGwtcUvjlgTWM26pGGuFW3mM5XCriJnXC26VMe%2F1iltVjOVxq46x3nCribHWuDWLsTa41cZY2yv%2BX%2B%2FQejwpU2g9odYHbrEY6xO3eIz1hVsixmIUx7IojF1h%2BpVrfodVu6Xzp5W0u2Q6pyrrxdy7zaoZXqrmaXebBPMHOJy6D4UHAAA%3D%22%7D"
export var colorSetVariants = {black:'_7kur23k',greyMid:'_7kur23l',greyLight:'_7kur23m',white:'_7kur23n',gum:'_7kur23o',gumLight:'_7kur23p',pinkLight:'_7kur23q',redLight:'_7kur23r',orangeLight:'_7kur23s',blueLight:'_7kur23t',error:'_7kur23u','default':'_7kur23v'};
export var colorSetVars = {main:'var(--_7kur23g)',contrastText:'var(--_7kur23h)'};
export var colorValues = {black:'#000',greyLight:'#FAFAFA',greyMid:'#E8E8E8',white:'#fff',gum:'#075B56',gumLight:'#E9F7F3',pinkLight:'#F6F5FB',redLight:'#FCF1F1',orangeLight:'#FDF1EC',blueLight:'#EFF6FF',error:'#e20000'};
export var colorVars = {black:'var(--_7kur235)',greyLight:'var(--_7kur236)',greyMid:'var(--_7kur237)',white:'var(--_7kur238)',gum:'var(--_7kur239)',gumLight:'var(--_7kur23a)',pinkLight:'var(--_7kur23b)',redLight:'var(--_7kur23c)',orangeLight:'var(--_7kur23d)',blueLight:'var(--_7kur23e)',error:'var(--_7kur23f)'};
var __default__ = {colors:colorVars,colorSets:{black:{main:'var(--_7kur235)',contrastText:'var(--_7kur238)'},greyMid:{main:'var(--_7kur237)',contrastText:'var(--_7kur235)'},greyLight:{main:'var(--_7kur236)',contrastText:'var(--_7kur235)'},white:{main:'var(--_7kur238)',contrastText:'var(--_7kur235)'},gum:{main:'var(--_7kur239)',contrastText:'var(--_7kur238)'},gumLight:{main:'var(--_7kur23a)',contrastText:'var(--_7kur235)'},pinkLight:{main:'var(--_7kur23b)',contrastText:'var(--_7kur235)'},redLight:{main:'var(--_7kur23c)',contrastText:'var(--_7kur235)'},orangeLight:{main:'var(--_7kur23d)',contrastText:'var(--_7kur235)'},blueLight:{main:'var(--_7kur23e)',contrastText:'var(--_7kur235)'},error:{main:'var(--_7kur23f)',contrastText:'var(--_7kur235)'}},tintSets:{gumLight:{main:'var(--_7kur230)',contrastText:'var(--_7kur235)'},pinkLight:{main:'var(--_7kur231)',contrastText:'var(--_7kur235)'},redLight:{main:'var(--_7kur232)',contrastText:'var(--_7kur235)'},orangeLight:{main:'var(--_7kur233)',contrastText:'var(--_7kur235)'},blueLight:{main:'var(--_7kur234)',contrastText:'var(--_7kur235)'}}};
export default __default__;
export var tintSetVariants = {gumLight:'_7kur23w',pinkLight:'_7kur23x',redLight:'_7kur23y',orangeLight:'_7kur23z',blueLight:'_7kur2310','default':'_7kur2311'};
export var tintSetVars = {main:'var(--_7kur23i)',contrastText:'var(--_7kur23j)'};
export var tintValues = {gumLight:'rgba(233, 247, 243, 0.75)',pinkLight:'rgba(246, 245, 251, 0.75)',redLight:'rgba(252, 241, 241, 0.75)',orangeLight:'rgba(253, 241, 236, 0.75)',blueLight:'rgba(239, 246, 255, 0.75)'};
export var tintVars = {gumLight:'var(--_7kur230)',pinkLight:'var(--_7kur231)',redLight:'var(--_7kur232)',orangeLight:'var(--_7kur233)',blueLight:'var(--_7kur234)'};