'use client';

import Txt, { TxtProps } from '../ui/Txt';

type EntryIndexTitleProps = TxtProps<
  'span',
  {
    message?: string;
  }
>;

const EntryIndexTitle = ({ message, ...props }: EntryIndexTitleProps) => {
  if (!message) return null;
  return (
    <Txt as="h2" variant="h2" {...props}>
      {message}
    </Txt>
  );
};

export default EntryIndexTitle;
