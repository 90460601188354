'use client';

import { CONTENT_TYPE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { simplifyCategory } from '@/lib/utils/format';
import { withFragment } from '@liquorice/gql-utils';
import { makeNonNullableArray, parseUri } from '@liquorice/utils';
import Card from '../Card';
import Box from '../ui/Box';
import { ARTICLE_CARD_FRAGMENT } from './ArticleCard';
import { OverrideCardProps } from './EntryCard';
import * as styles from './GuideCard.css';

const GuideCard = withFragment(ARTICLE_CARD_FRAGMENT, (data, props: OverrideCardProps) => {
  if (!data) return null;

  const { entryImage, entryTitle, uri, contentTypeCategory } = data;
  const { heading } = props;

  const href = parseUri(uri);
  const categories = simplifyCategory(CONTENT_TYPE_CATEGORY_FRAGMENT, contentTypeCategory);
  const maybeImage = makeNonNullableArray(props.image);
  const image = !!maybeImage.length ? maybeImage : entryImage;

  return (
    <Card
      elevation={false}
      fullHeight
      className={styles.root}
      item={{
        title: heading ?? entryTitle,
        image,
        categories,
        href,
      }}>
      <Box className={styles.imageWrapper}>
        <Card.Image ratio="PORTRAIT" hover={false} />
      </Box>
      <Box className={styles.inner}>
        <Card.Body cx={{ rowGap: 'md' }}>
          <Card.Categories variant="outlined" color="gum" disableLinkWrap />
          <Card.Title variant="h4" cx={{ decoration: 'none' }} />
          <Card.Cta />
        </Card.Body>
      </Box>
    </Card>
  );
});

export default GuideCard;
