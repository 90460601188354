import IconButton from '@/components/IconButton';
import Btn from '@/components/ui/Btn';
import Txt from '@/components/ui/Txt';
import { NavMenuItem } from '@/lib/parsers/navigation';
import { useNavBarActions } from '../useNavBar';

type NavBarParentProps = NavMenuItem & { isAccordion?: boolean };

export default function NavBarParent({
  title,
  type,
  subItems,
  newWindow,
  external,
  parentId,
  isAccordion,
  ...props
}: NavBarParentProps) {
  const { menu, setMenu } = useNavBarActions();

  const toggleMenu = () => {
    setMenu(!menu);
  };

  return (
    <Btn
      variant="nav"
      underline="none"
      justify="between"
      style={{ width: '100%' }}
      onClick={toggleMenu}
      {...props}>
      <Txt as="span" variant="h5">
        {title}
      </Txt>
      {!isAccordion && <IconButton icon="arrowRight" variant="outlined" size="small" />}
    </Btn>
  );
}
