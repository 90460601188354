'use client';

import Box, { BoxProps } from '@/components/ui/Box';
import Container, { ContainerProps } from '@/components/ui/Container';
import { useAppContext } from '@/lib/context/hooks';
import { BlockMeta } from '@/lib/parsers/blocks';
import { useForwardedRef } from '@/lib/utils/useForwardedRef';
import { Sprinkles } from '@/theme/sprinkles.css';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { isTypedFragment } from '@liquorice/gql-utils';
import { slugify } from '@liquorice/utils';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import * as style from './BlockContainer.css';

export type BlockContainerProps = BoxProps<
  'section',
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    ContainerProps?: ContainerProps;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    maxWidth?: ContainerProps['maxWidth'];
    meta?: BlockMeta;
    paddingY?: Sprinkles['paddingY'] | true;
    marginY?: Sprinkles['marginY'] | true;
    overflow?: boolean;
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    marginY,
    paddingY,
    overflow,
    cx,
    maxWidth,
    ContainerProps,
    GridProps,
    GridColProps,
    meta,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const ref = useForwardedRef(forwardedRef);
  const isLast = !!meta?.last;
  const isFirst = !!meta?.first;

  const { data: page, addQuickLink } = useAppContext() ?? {};
  // const test = useEntryContext()?.setState();
  const isProfile = isTypedFragment(page, 'profile_Entry');
  const isInsight = isTypedFragment(page, 'insight_Entry');
  const isArticle = isTypedFragment(page, 'article_Entry');
  const isService = isTypedFragment(page, 'service_Entry');

  const isGridlayout = isProfile || isInsight || isArticle || isService;

  useEffect(() => {
    if (meta?.anchor && !!meta && addQuickLink) {
      addQuickLink({
        heading: meta.anchor,
        id: `${meta.index}-${meta.typename}`,
        href: `#${slugify(meta.anchor)}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, page]);

  /**
   * If disableContainer is true, we don't render a container
   * If disableContainer is false, we render a container with the maxWidth provided
   */
  let inner = children;

  const customGridColProps = {
    ...(!isGridlayout && GridColProps),
  };

  if (!disableContainer)
    inner = (
      <Container
        {...{
          maxWidth,
          disablePadding: isGridlayout,
          ...mergePropsClassName(ContainerProps, style.container({ overflow })),
        }}>
        <Grid {...mergePropsClassName(GridProps, style.grid)}>
          <Grid.Col {...mergePropsClassName(customGridColProps, style.gridCol)}>
            {children}
          </Grid.Col>
        </Grid>
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
      first: isFirst,
    })
  );

  const globalSpacing = '2xl';
  // const globalEndSpacing = '4xl';

  const blockSpace = globalSpacing;

  let blockTopMargin = marginY === true ? blockSpace : marginY;
  let blockBottomMargin = marginY === true ? blockSpace : marginY;
  const blockPadding = paddingY === true ? blockSpace : paddingY;

  if (isFirst && isProfile) blockTopMargin = 'none';
  // if (isFirst) blockTopMargin = globalEndSpacing;
  // if (isLast) blockBottomMargin = globalEndSpacing;
  if (paddingY === true && (isFirst || isLast)) blockBottomMargin = 'none';
  if (paddingY === true && (isFirst || isLast)) blockTopMargin = 'none';

  return (
    <Box
      as="section"
      className={className}
      id={meta?.anchor ? slugify(meta.anchor) : undefined}
      data-block={`${meta?.typename.split('_')[1]}-${meta?.index}`}
      ref={ref}
      {...props}
      cx={{
        mT: blockTopMargin,
        mB: blockBottomMargin,
        pY: blockPadding,
        ...cx,
      }}>
      {inner}
    </Box>
  );
});
