import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { makeElementTree } from '@/lib/parsers/common/makeElementTree';
import { ElementTreeItem } from '@/lib/parsers/common/parseElementList';
import React from 'react';

// ----------------------------------------------------------------------------

// Create context for a filter slice

// export const { Provider: FilterContextProvider } = React.createContext<
//   FilterSliceState | undefined
// >(undefined);

// ----------------------------------------------------------------------------
/**
 * @deprecated
 */
export const useDepreciatedEntryIndexFilter = (filterId: string) => {
  const { add, remove, clear } = useEntryIndex((s) => {
    return {
      add: (value: string) => s.filterSelect(filterId, value),
      remove: (value: string) => s.filterRemove(filterId, value),
      clear: () => s.filterClear(filterId),
    };
  });

  const { items = [], label } = useEntryIndex((s) => s.filters[filterId]) ?? {};

  const itemTree = React.useMemo(() => makeElementTree(items, filterId), [filterId, items]);

  const selection = useEntryIndex((s) => s.filterSelections[filterId] ?? []);

  return { selection, items, itemTree, label, add, remove, clear };
};

export const useEntryIndexFilterItem = (item: ElementTreeItem) => {
  const { id, collectionId } = item;
  const { selection, add, remove } = useDepreciatedEntryIndexFilter(collectionId);

  const active = selection.includes(id);

  const toggle = React.useCallback(
    (force?: boolean) => {
      const newValue = force ?? !active;
      if (newValue) {
        add(id);
      } else {
        remove(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active]
  );

  return { active, toggle };
};

// ----------------------------------------------------------------------------
