'use client';

import { THEME_ENTRY_FRAGMENT } from '@/gql/fragments/entries.gql';
import { TintSetKey } from '@/theme/partials/palette.css';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { getFragmentData } from '__generated__';
import { HomeEntryFragment } from '__generated__/graphql';
import { useEffect } from 'react';
import ImageAsset from '../ImageAsset';
import { useNavBarActions } from '../NavBar/useNavBar';
import Box from '../ui/Box';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';
import PageHeroCard from './PageHeroCard';

export default function PageHeaderHome({ theme, ...props }: HomeEntryFragment) {
  const heroThemes = getFragmentData(THEME_ENTRY_FRAGMENT, excludeEmptyTypedElements(theme));
  const { theme: activeTheme, setTheme, height } = useNavBarActions();

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * heroThemes.length);
    setTheme(heroThemes[randomIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const image = activeTheme?.imageSingle;
  const tintSet = activeTheme?.colorScheme?.label as TintSetKey;

  return (
    <PageHeaderRoot
      variant="home"
      paper
      afterContainer={
        <Box
          className={styles.home}
          style={{ ...assignInlineVars({ [styles.heroCardMargin]: `${height}px` }) }}>
          <PageHeroCard {...{ PageProps: props, tintSet }} />
          <Box className={styles.imageWrapper}>
            <ImageAsset
              priority
              layout="fillParent"
              data={image}
              wrapProps={{ className: styles.image }}
            />
          </Box>
        </Box>
      }
    />
  );
}
