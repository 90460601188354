import { gql } from '__generated__';

export const CATEGORY_ID_FRAGMENT = gql(`
    fragment categoryId on CategoryInterface {
      __typename
      id
      title
      parent {
        id
      }
    }
`);

export const CONTENT_TYPE_CATEGORY_FRAGMENT = gql(`
    fragment contentTypeCategory on contentTypeCategory_Category {
      __typename
      id
      uri
      slug
      groupHandle
      title
    }
`);

export const LOCATION_CATEGORY_FRAGMENT = gql(`
  fragment locationCategory on locationCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
  }
`);

export const PROFILE_ROLE_CATEGORY_FRAGMENT = gql(`
  fragment profileRoleCategory on profileRoleCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
  }
`);

export const CATEGORIES_FRAGMENT = gql(`
    fragment categories on CategoryInterface {
      ...contentTypeCategory
      ...locationCategory
      ...profileRoleCategory
    }
`);
