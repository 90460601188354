import { SimplifiedEntry } from '@/lib/utils/format';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { isArray } from 'lodash';
import React from 'react';
import Divider from '../ui/Divider';
import Flex, { FlexProps } from '../ui/Flex';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardMetaProps<D extends React.ElementType = 'h2'> = TxtProps<
  D,
  { WrapperProps?: FlexProps }
>;

export const CardMeta = <D extends React.ElementType>({
  children,
  WrapperProps,
  ...props
}: CardMetaProps<D>) => {
  const { meta } = useCard();
  const content = children ?? meta;

  const hasContent = !!content && isArray(content);

  return content ? (
    hasContent ? (
      <Flex direction="row" columnGap="3xs" rowGap="3xs" {...WrapperProps}>
        {content.map((item: SimplifiedEntry, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <Txt as="span" {...mergePropsClassName(props, style.meta)}>
                {item.title}
              </Txt>
              {idx !== content.length - 1 && (
                <Divider direction="vertical" color="accent" style={{ height: '1em' }} />
              )}
            </React.Fragment>
          );
        })}
      </Flex>
    ) : (
      <Txt as="span" {...mergePropsClassName(props, style.meta)}>
        {content}
      </Txt>
    )
  ) : null;
};
